import Badges from 'components/FormElements/Badges';
import Button from 'components/FormElements/Button';
import { exportCSVTOExcel } from 'components/Function';
import Loading from 'components/Loading/Loading';
import Table from 'components/Table/Table'
import { attentionToast } from 'components/Toast/AllToast';
import { Edit } from 'iconsax-react';
import { TrushSquare } from 'iconsax-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';

function DownloadedReportsTable({ data, setTableData, buttonAction }) {

    const navigate = useNavigate()

    const [exportLoading, setExportLoading] = useState(false)

    const ignoreHeader = [
        "col1",
        "col5",
        "col7",
        "col8"
    ]


    const exportExcel = (data) => {
        let date = new Date()

        // let startDate = listType === "all" ? saleReportList?.start_date : productFilter?.date_between_start
        // let endDate = listType === "all" ? saleReportList?.end_date : productFilter?.date_between_end


        setExportLoading(true)
        exportCSVTOExcel(data.data, `${data.supplier_staff_name}__${moment(data.create_date).format("DD/MM/YYYY hh:mm")}`)
    }


    function settings(props) {
        return <div className="flex items-center space-x-2">
            {exportLoading ?
                <Loading text="İndiriliyor" width={20} height={20} textSize="text-xs" />
                :
                <Button
                    type="main-success-button"
                    title={`Raporu İndir`}
                    size="medium"
                    buttonType="button"
                    onClick={() => exportExcel(props.row.original.content)}
                />
            }
        </div>
    }

    function active(props) {
        return <div>
            {props.value ?
                <div className="flex items-center justify-center px-1 py-1 space-x-1 rounded-full bg-slime-girl-100">
                    <Badges
                        status="success"
                        size="small"
                    />
                    <p className="text-slime-girl-600">Aktif</p>
                </div>
                :
                <div className="flex items-center justify-center px-1 py-1 space-x-1 rounded-full bg-black-river-falls-200">
                    <Badges
                        size="small"
                    />
                    <p className="text-black-river-falls-400">Pasif</p>
                </div>
            }
        </div>
    }

    function status(props) {
        return (
            <div>
                {props.value === "Done" ?
                    <div>Tamamlandı</div>
                    :
                    props.value === "Start" ?
                        <div>Başladı</div>
                        :
                        props.value === "Waiting" ?
                            <div>Bekliyor</div>
                            :
                            props.value === "Cancel" ?
                                <div>İptal Edildi</div>
                                :
                                <></>
                }
            </div>
        )
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'İndiren Kişi',
                accessor: 'content.supplier_staff_name',
            },
            {
                Header: 'Firması',
                accessor: 'content.supplier_name',
            },
            {
                Header: 'Rapor Tarihi',
                accessor: 'content.create_date',
                Cell: (props) => moment(props.value).format("DD/MM/YYYY hh:mm")

            },
            {
                Header: 'Aktiflik',
                accessor: 'content.active',
                Cell: active
            },
            {
                Header: 'Durum',
                accessor: 'content.state',
                Cell: status
            },
            {
                Header: 'Ayarlar',
                accessor: 'col8',
                Cell: settings
            },
        ],
        []
    )

    useEffect(() => {
        let excelData = data.map((item, index) => {
            const returnData = {}
            Object.entries(columns).forEach(([key, value]) => {
                let splitAccessor = value.accessor.split(".")
                let tempValue = item
                splitAccessor.forEach((x) => {
                    tempValue = tempValue[x]
                })

                let isAvaible = ignoreHeader.includes(value.accessor)

                if (!isAvaible) {
                    returnData[value.Header] = tempValue
                }

            })
            return returnData
        })
        setTableData(excelData)
    }, [])

    return (
        <div className=''>
            <Table data={data} columns={columns} />
        </div>
    )
}

export default DownloadedReportsTable