import React from 'react'
import Badges from './Badges'


function ProfileImage({
  image,
  name,
  alt,
  status,
  size,
}) {
  return (
    <div className="unselectable">
      <div
        className={`
        ${size === "xlsmall" && "w-[28px] h-[28px]"} 
        ${size === "xsmall" && "w-[32px] h-[32px]"} 
        ${size === "small" && "w-[36px] h-[36px]"} 
        ${size === "regular" && "w-[40px] h-[40px]"} 
        ${size === "large" && "w-[48px] h-[48px]"} 
        ${!size && "w-[80px] h-[80px]"} 
       bg-black-river-falls-300
        flex justify-center  items-center rounded-full relative`}
      >
        {image ?
          <img className='rounded-full w-full h-full' src={image ? image : "/muratpp.png"} alt={alt} />
          :
          <p className={`${size === "xlsmall" && "text-xs"} text-black-river-falls-500 `}>{name ? (name.split(" ").length > 1 && name.split(" ").length < 3 ? (name.split(" ")[0].charAt(0) + "." + name.split(" ")[1].charAt(0).toUpperCase()) : name.charAt(0)) : "BO"}</p>
        }
        {status &&
          <div className="absolute bottom-0 right-0">
            <Badges
              status={status}
              size={size}
            />
          </div>
        }
      </div>

    </div>
  )
}

export default ProfileImage