import Badges from 'components/FormElements/Badges';
import { priceFormat } from 'components/Function';
import Table from 'components/Table/Table'
import { Edit2 } from 'iconsax-react';
import { ExportSquare } from 'iconsax-react';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import slugify from 'slugify';

function ProductRequestTable({ setTableData, data, openDetailModal }) {

    const ignoreHeader = [
        "content.status"
    ]
    
    const navigate = useNavigate()
    
    const sumValues = (obj) => {
       let number = Object.values(obj).reduce((a, b) => a + b, 0)
       return number
    }

    function status(props) {
        return <div>
            {props.value === "A" &&
                <div className="flex items-center space-x-1 justify-center bg-slime-girl-100 rounded-full px-1 py-1">
                    <Badges
                        status="success"
                        size="small"
                    />
                    <p className="text-slime-girl-600">Aktif</p>
                </div>
            }
            {props.value === "D" &&
                <div className="flex items-center space-x-1 justify-center bg-black-river-falls-200 rounded-full px-1 py-1">
                    <Badges
                        size="small"
                    />
                    <p className="text-black-river-falls-400">Pasif</p>
                </div>
            }
            {props.value === "H" &&
                <div className="flex items-center space-x-1 justify-center bg-black-river-falls-200 rounded-full px-1 py-1">
                    <Badges
                        size="small"
                    />
                    <p className="text-black-river-falls-400">Gizli</p>
                </div>
            }
        </div>
    }

    function product(props){
        return <div className="flex space-x-2 items-center rounded-md" >
            <img src={props.row.original.content.medium_image ? props.row.original.content.medium_image : "/dummyProduct.png"} width={50} height={50} 
                         onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/dummyProduct.png';
                        }} 
                        className="rounded-md"
                        />
            <p className="text-black-river-falls-500 text-sm">{props.value}</p>
        </div>
    }

    function options(props) {
        return <div className="flex space-x-3" >
            <div className="flex items-center justify-center space-x-2 px-3 py-2 border border-black-river-falls-200 rounded-md cursor-pointer" onClick={() => openDetailModal(props.row.original)} >
                <p className="text-black-river-falls-500 text-sm">Detay</p>
                <ExportSquare color="#64748B" size={16} />
            </div>
        </div>
    }

    const columns = 
   [
            {
                Header: 'Ürün',
                accessor: 'content.product_name',
                minWidth: 300,
                Cell: product
            },
            {
                Header: 'Marka',
                accessor: 'content.brand',
                Cell: props => <p className="text-black-river-falls-500 text-sm pt-1.5">{props.value}</p>

            },
            {
                Header: 'Barkod',
                accessor: 'content.barcode'
            },
            {
                Header: "Seçenekler",
                accessor: "content.status",
                minWidth: 200,
                Cell: options
            }
        ]
  
    

    useEffect(() => {

        let excelData = data.map((item, index) => {
            const returnData = {}
            Object.entries(columns).forEach(([key, value]) => {
                let splitAccessor = value.accessor.split(".")
                let tempValue = item
                splitAccessor.forEach((x) => {
                    tempValue = tempValue[x]
                })

                let isAvaible = ignoreHeader.includes(value.accessor)

                if (!isAvaible) {
                    returnData[value.Header] = tempValue
                }

            })
            return returnData
        })
        setTableData(excelData)
    }, [])


    return (
        <div>
            <Table data={data} columns={columns} />
        </div>
    )
}

export default ProductRequestTable