import { TickCircle } from "iconsax-react";
import { CloseCircle } from "iconsax-react";
import { Danger } from "iconsax-react";
import { InfoCircle } from "iconsax-react";
import toast from "react-hot-toast";

/**
 * Tosts componenti
 * @param {string} title - Başlık.
 * @param {string} description - Açıklama yazısı
 * @param {string} position - Toastun çıkacağı yeri belirlemek için kullanılır.
 */
export async function successToast(title, description, position) {
  toast.custom(
    <div className="grid grid-cols-12 w-[330px] bg-white px-5 py-3 border border-opacity-50 border-black-river-falls-300 shadow-xl rounded-md">
      <div className="col-span-2 my-auto flex justify-center items-center rounded-full w-[36px] h-[36px] bg-slime-girl-500">
        <TickCircle size={20} color="white" />
      </div>
      <div className="col-span-8 flex flex-col space-y-1 ">
        <div className="text-black-river-falls-600 text-sm"><p>{title ? title : "İşlem Başarılı"}</p></div>
        <div className="text-black-river-falls-500 text-xs"><p>{description ? description : "İşlemi gerçekleştiremedik. Yeniden deneyin."}</p></div>
      </div>
      <div className="col-span-2 flex justify-end cursor-pointer" onClick={() => toast.remove()}>
        <CloseCircle
          size={20}
          color="#CBD5E1"
        />
      </div>
    </div>,
    { position: position ? position : "top-center" }
  )
  return successToast;
}

export async function alertToast(title, description, position) {
  toast.custom(
    <div className="grid grid-cols-12 w-[330px] bg-white px-5 py-3 border border-opacity-50 border-black-river-falls-300 shadow-xl rounded-md">
      <div className="col-span-2 my-auto flex justify-center items-center rounded-full w-[36px] h-[36px] bg-coral-red-500 ">
        <InfoCircle size={20} color="white" />
      </div>
      <div className="col-span-8 flex flex-col space-y-1 ">
        <div className="text-black-river-falls-600 text-sm"><p>{title ? title : "İşlem Yapılamaz"}</p></div>
        <div className="text-black-river-falls-500 text-xs "><p>{description ? description : "İşlemi gerçekleştiremedik. Yeniden deneyin."}</p></div>
      </div>
      <div className="col-span-2 flex justify-end cursor-pointer" onClick={() => toast.remove()}>
        <CloseCircle
          size={20}
          color="#CBD5E1"
        />
      </div>
    </div>,
    { position: position ? position : "top-center" }
  )
  return alertToast;
}

export async function attentionToast(title, description, position) {
  toast.custom(
    <div className="grid grid-cols-12 w-[330px] bg-white px-5 py-3 border border-opacity-50 border-black-river-falls-300 shadow-xl rounded-md">
      <div className="col-span-2 my-auto flex justify-center items-center rounded-full w-[36px] h-[36px] bg-fall-gold-500">
        <Danger size={20} color="white" />
      </div>
      <div className="col-span-8 flex flex-col space-y-1 ">
        <div className="text-black-river-falls-600 text-sm"><p>{title ? title : "İşlem Yapılamadı"}</p></div>
        <div className="text-black-river-falls-500 text-xs"><p>{description ? description : "İşlemi gerçekleştiremedik. Yeniden deneyin."}</p></div>
      </div>
      <div className="col-span-2 flex justify-end cursor-pointer" onClick={() => toast.remove()}>
        <CloseCircle
          size={20}
          color="#CBD5E1"
        />
      </div>
    </div>,
    { position: position ? position : "top-center" }
  )
  return attentionToast;
}

export async function informationToast(title, description, position) {
  toast.custom(
    <div className="grid grid-cols-12 w-[330px] bg-white px-5 py-3 border border-opacity-50 border-black-river-falls-300 shadow-xl rounded-md">
      <div className="col-span-2 my-auto flex justify-center items-center rounded-full w-[36px] h-[36px] bg-glitter-lake-500">
        <InfoCircle size={20} color="white" />
      </div>
      <div className="col-span-8 flex flex-col space-y-1 ">
        <div className="text-black-river-falls-600 text-sm"><p>{title ? title : "İşlem Bilgilendirme"}</p></div>
        <div className="text-black-river-falls-500 text-xs"><p>{description ? description : "İşlemi gerçekleştiremedik. Yeniden deneyin."}</p></div>
      </div>
      <div className="col-span-2 flex justify-end cursor-pointer" onClick={() => toast.remove()}>
        <CloseCircle
          size={20}
          color="#CBD5E1"
        />
      </div>
    </div>,
    { position: position ? position : "top-center" }
  )
  return informationToast;
}