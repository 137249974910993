import Button from 'components/FormElements/Button';
import Table from 'components/Table/Table'
import { Edit } from 'iconsax-react';
import { TrushSquare } from 'iconsax-react';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';

function SupplierStaffTable({ data, setTableData }) {

    const navigate = useNavigate()

    const ignoreHeader = [
        "col1",
        "col5",
        "col7",
        "col8"
    ]

    function settings(props) {
        return <div className="flex items-center space-x-2">
            <div className="cursor-pointer" onClick={() => navigate(`/tedarikciler/tedarikci-duzenle/${props.row.original.id}`)}>
                <Edit
                    size={20}
                    variant="Linear"
                    className="font-bold text-black-river-falls-500"
                />
            </div>

        </div>
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Tedarikçi',
                accessor: 'content.supplier_name',

            },
            {
                Header: 'Ayarlar',
                accessor: 'col8',
                Cell: settings
            },
        ],
        []
    )

    useEffect(() => {
        let excelData = data.map((item, index) => {
            const returnData = {}
            Object.entries(columns).forEach(([key, value]) => {
                let splitAccessor = value.accessor.split(".")
                let tempValue = item
                splitAccessor.forEach((x) => {
                    tempValue = tempValue[x]
                })

                let isAvaible = ignoreHeader.includes(value.accessor)

                if (!isAvaible) {
                    returnData[value.Header] = tempValue
                }

            })
            return returnData
        })
        setTableData(excelData)
    }, [])

    return (
        <div className=''>
            <Table data={data} columns={columns} />
        </div>
    )
}

export default SupplierStaffTable