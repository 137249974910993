import Table from 'components/Table/Table'
import { Edit } from 'iconsax-react';
import { TrushSquare } from 'iconsax-react';
import moment from 'moment/moment';
import React from 'react'

function StaffDepartmentTable({ data, openEditModal, openDeleteModal }) {
    // const data = React.useMemo(
    //     () => [
    //         {
    //             col1: "1",
    //             col2: "Murat TOKTAŞ",
    //             col3: "info@makdos.com",
    //             col4: "2022 Active Users",
    //             col5: "23.05.2022 13:00",
    //             col6: true,
    //             col7: true,
    //         },
    //         {
    //             col1: "2",
    //             col2: "Utku Sezici",
    //             col3: "info@makdos.com",
    //             col4: "2022 Active Users",
    //             col5: "23.05.2022 13:00",
    //             col6: true,
    //             col7: false,
    //         },
    //         {
    //             col1: "3",
    //             col2: "Berke Özenses",
    //             col3: "info@makdos.com",
    //             col4: "2022 Active Users",
    //             col5: "23.05.2022 13:00",
    //             col6: true,
    //             col7: true,
    //         },
    //         {
    //             col1: "1",
    //             col2: "Murat TOKTAŞ",
    //             col3: "info@makdos.com",
    //             col4: "2022 Active Users",
    //             col5: "23.05.2022 13:00",
    //             col6: true,
    //             col7: true,
    //         },
    //         {
    //             col1: "2",
    //             col2: "Utku Sezici",
    //             col3: "info@makdos.com",
    //             col4: "2022 Active Users",
    //             col5: "23.05.2022 13:00",
    //             col6: true,
    //             col7: false,
    //         },
    //         {
    //             col1: "3",
    //             col2: "Berke Özenses",
    //             col3: "info@makdos.com",
    //             col4: "2022 Active Users",
    //             col5: "23.05.2022 13:00",
    //             col6: true,
    //             col7: true,
    //         },
    //         {
    //             col1: "1",
    //             col2: "Murat TOKTAŞ",
    //             col3: "info@makdos.com",
    //             col4: "2022 Active Users",
    //             col5: "23.05.2022 13:00",
    //             col6: "unkown",
    //             col7: true,
    //         },
    //         {
    //             col1: "2",
    //             col2: "Utku Sezici",
    //             col3: "info@makdos.com",
    //             col4: "2022 Active Users",
    //             col5: "23.05.2022 13:00",
    //             col6: true,
    //             col7: "unkown",
    //         },
    //         {
    //             col1: "3",
    //             col2: "Berke Özenses",
    //             col3: "info@makdos.com",
    //             col4: "2022 Active Users",
    //             col5: "23.05.2022 13:00",
    //             col6: true,
    //             col7: true,
    //         },
    //         {
    //             col1: "1",
    //             col2: "Murat TOKTAŞ",
    //             col3: "info@makdos.com",
    //             col4: "2022 Active Users",
    //             col5: "23.05.2022 13:00",
    //             col6: "unkown",
    //             col7: true,
    //         },

    //     ],
    //     []
    // );

    function settings(props) {
        return <div className="flex space-x-2 items-center">
            <div className="cursor-pointer" onClick={() => openDeleteModal(props.row.original.id)}>
                <TrushSquare
                    size={20}
                    className="text-coral-red-500 font-bold"
                />
            </div>
            <div className="cursor-pointer" onClick={() => openEditModal(props.row.original.id)}>
                <Edit
                    size={20}
                    variant="Linear"
                    className="text-black-river-falls-500 font-bold"
                />
            </div>
        </div>
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Departman Adı',
                accessor: 'content.department_name',
            },
            {
                Header: 'Kullanıcı Sayısı',
                accessor: 'col3',

            },
            {
                Header: 'Oluşturma Tarihi',
                accessor: 'created_at',
                Cell: (props) => moment(props.value).format("DD/MM/YYYY ")

            },
            {
                Header: 'Durum',
                accessor: 'col5',

            },
            {
                Header: 'Ayarlar',
                accessor: 'col8',
                Cell: settings
            },
        ],
        []
    )
    return (
        <div className=''>
            <Table data={data} columns={columns} isCheckbox />
        </div>
    )
}

export default StaffDepartmentTable