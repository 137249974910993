import Button from 'components/FormElements/Button'
import ModalLayout from 'components/ModalLayout/ModalLayout'
import { Danger } from 'iconsax-react'
import React from 'react'

function AttentionModal({ title, description, buttonText, buttonAction, secondaryButtonAction, closeModal }) {
    return (
        <ModalLayout
            hideLine
            closeModal={() => closeModal()}
        >
            <div className="flex flex-col w-[400px] justify-center items-center pt-8 space-y-3">
                <div className="flex rounded-full w-[70px] h-[70px] bg-fall-gold-500 items-center justify-center">
                    <Danger size={36} color="white" />
                </div>
                <div className="flex flex-col space-y-3 items-center">
                    <p className="text-xl font-semibold text-black-river-falls-600">{title ? title : "İşlem Yapılamadı"}</p>
                    <p className="text-sm text-black-river-falls-500">{description ? description : "İşlemi gerçekleştiremedik. Yeniden deneyin."}</p>
                </div>
                <hr className='my-3 border-black-river-falls-100' />
                <div className="flex justify-center space-x-5 w-full pt-10">
                    <Button
                        type="tertiary"
                        title="Vazgeç"
                        style="w-full"
                        size="medium"
                        onClick={() => secondaryButtonAction ? secondaryButtonAction() : closeModal()}
                    />
                    <Button
                        type="main-warning-button"
                        title={buttonText ? buttonText : "Tekrar Dene"}
                        size="medium"
                        style="w-full"
                        buttonType="button"
                        onClick={() => buttonAction()}
                    />
                </div>
            </div>
        </ModalLayout>
    )
}

export default AttentionModal