import React, { useEffect, useRef, useState } from "react";

function TextInput({
  label,
  name,
  value,
  onChange,
  type = "text",
  error,
  success,
  warning,
  required,
  disabled,
  inputState="default",
  errorStyle,
  icon,
  innerRef,
  inputMode
}) {
  let defaultInputStyle = `w-full px-2 font-regular rounded-md  text-black-river-falls-500 peer focus:pt-3 pt-3 placeholder-transparent`;
  let iconStyle = `absolute text-black-river-falls-400 top-2.5 peer-focus:hidden  right-2`
  let formStyle = `w-full h-11 relative`;
  let labelStyle = `${"-top-1 text-xs top-1 text-black-river-falls-400 absolute left-0.5 px-1 duration-300 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2.5 peer-focus:top-1 peer-focus:text-sPink peer-focus:text-xs cursor-pointer duration-300"
    }`;
  let inputStyle = `${defaultInputStyle} ${formStyle} 
  ${inputState === "default" && !disabled
      ? "border border-black-river-falls-200 focus:outline-none focus:ring-0 focus:border-black-river-falls-400"
      : disabled
        ? "cursor-not-allowed border-none bg-black-river-falls-200  focus:ring-none"
        : ""
    }
  ${inputState === "error" && "border  focus:outline-none focus:ring-0 border-coral-red-500"

    }
  ${inputState === "success" && "border focus:outline-none focus:ring-0 border-slime-girl-500"

    }
  ${inputState === "warning" && "border focus:outline-none focus:ring-0 border-fall-gold-500"

    }
  ${inputState === "primary" && "border focus:outline-none focus:ring-0 border-glitter-lake-500"

    }
    `
  const [change, setChange] = useState()

  useEffect(() => {
    if (onChange) {
      setChange({ onChange: onChange })
    }
  }, [onChange, type]);


  let inputRef = useRef(null);
  return (
    <div className={formStyle}>
        <input
          ref={inputRef}
          type={type}
          className={inputStyle}
          id={name}
          name={name}
          placeholder={label}
          value={value}
          required={required}
          disabled={disabled}
          {...change}
          {...innerRef}
          inputMode={inputMode && inputMode}
        />
        <label
          className={labelStyle}
          onClick={() => {
            inputRef.current?.focus();
          }}
          htmlFor={name}
        >
          {label}
        </label>
        {icon && (value == "" || typeof value === "undefined") && (
          <div className={iconStyle} htmlFor={name}>
            {icon}
          </div>
        )}
      {error && <div className={"text-coral-red-500 text-xs mt-1"}>{error}</div>}
      {success && <div className={"text-slime-girl-500 text-xs mt-1"}>{success}</div>}
      {warning && <div className={"text-fall-gold-500 text-xs mt-1 "}>{warning}</div>}
    </div>
  );
}

export default TextInput;
