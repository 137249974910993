import BrandTable from 'components/Brand/BrandTable'
import Button from 'components/FormElements/Button';
import Paginate from 'components/FormElements/Paginate';
import SearchBoxInput from 'components/FormElements/SearchBox';
import { useExcel } from 'hooks/useExcel';
import { FilterSearch } from 'iconsax-react';
import { Setting4 } from 'iconsax-react';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { getBrandList } from 'services/allServices'
import { useGlobalStore } from 'store/GlobalStore';

function BrandList() {
    const [brandList, setBrandList] = useState()

    const [setPaginateTotal, visibleCount] = useGlobalStore((state) => [state.setPaginateTotal, state.visibleCount]);

    const location = useLocation()
    const [onDownloadExcel] = useExcel()

    const [tableData, setTableData] = useState()

    const onGetBrandList = () => {
        setBrandList()
        getBrandList(visibleCount, location.search.split("=")[1]).then((res) => {
            setBrandList(res.category_list)
            setPaginateTotal(res.count)
        })
    }

    useEffect(() => {
        onGetBrandList()
    }, [visibleCount, location.search])

    return (
        <div className="white-container">
            <div className="flex justify-between items-center pb-3">
                <div className="flex space-x-2">
                    <Button
                        size="medium"
                        title="Excel Aktar"
                        type="main-success-button"
                        buttonType="button"
                        onClick={() => {
                            onDownloadExcel("Markalar", tableData)
                        }}
                    />
                </div>
                <div className="flex space-x-2">
                    <SearchBoxInput
                        label="Marka Ara"
                    />
                </div>
            </div>
            <div className="flex flex-col space-y-3">
                {brandList &&
                    <BrandTable data={brandList} setTableData={setTableData} />
                }
                <Paginate />
            </div>
        </div>
    )
}

export default BrandList