import React from 'react'

function EmptyData({ width, height, text, containerStyle }) {
    return (
        <div className={`${containerStyle ? containerStyle : ""}`}>
        <div className="flex justify-center">
            <div className="flex flex-col items-center space-y-3">
                <img src="/thinking.png" alt="thinking" width={width ? width : 120} height={height ? height : 120} />
                <h5 className="text-xl text-black-river-falls-700 font-semibold">{text ? text : "Veri bulunamadı"}</h5>
            </div>
        </div>
        </div>
    )
}

export default EmptyData