import Button from 'components/FormElements/Button'
import SelectBox from 'components/FormElements/SelectBox'
import Toggle from 'components/FormElements/Toggle'
import { TickSquare } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router'
import { getSupplier, updateSupplier } from 'services/allServices'
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { successToast } from 'components/Toast/AllToast'

const schema = yup.object().shape({

});

function ActionSupplier() {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const params = useParams()
    const navigate = useNavigate()

    const [supplierData, setSupplierData] = useState()

    const [chartTypeList, setChartTypeList] = useState([
        { id: "1", text: "Fiyat Bazlı", checked: false },
        { id: "2", text: "Satış Sayısı Bazlı", checked: false },
    ])

    const [selectedChartType, setSelectedChartType] = useState()
    const [canSeeMoney, setCanSeeMoney] = useState(false)

    const onGetSupplier = () => {
        getSupplier(params.id).then((res) => {
            if (res?.status === "record_not_found") {
                navigate("/tedarikciler/tedarikci-ayarlari")
            }
            else {
                setSupplierData(res)
                setCanSeeMoney(res?.content?.can_see_money)
                setSelectedChartType(res?.content?.chart_type === "money" ?
                    { id: "1", text: "Fiyat Bazlı", checked: false } :
                    { id: "2", text: "Satış Sayısı Bazlı", checked: false },
                )
            }
        })
    }

    const onSubmit = (data) => {
        let configure = {
            content: {
                supplier_name: supplierData.content.supplier_name,
                vkn: supplierData.content.vkn,
                mersis: supplierData.content.mersis,
                country: supplierData.content.country,
                city: supplierData.content.city,
                district: supplierData.content.district,
                state: supplierData.content.state,
                supplier_phone: supplierData.content.supplier_phone,
                address: supplierData.content.address,
                post_code: supplierData.content.post_code,
                tax_no: supplierData.content.tax_no,
                tax_office: supplierData.content.tax_office,
                can_see_money: canSeeMoney,
                chart_type: selectedChartType.id === "1" ? "money" : "quantity",
            }
        }
        updateSupplier(configure, params.id).then(() => {
            successToast("Tedarikçi Ayarları", "Tedarikçi ayarları başarıyla güncellendi.")
            navigate("/tedarikciler/tedarikci-ayarlari")
        })
    }

    useEffect(() => {
        if (params.id) {
            onGetSupplier()
        }
    }, [])

    return (
        <div className="white-container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col space-y-4">
                    <div className="flex items-center justify-between w-full">
                        <h2 className="text-xl font-semibold text-porcelain-rose-500">{supplierData?.content.supplier_name}</h2>
                        <Button
                            type="main"
                            size="medium"
                            title="Kaydet"
                            iconRight={<TickSquare size={20} color="white" />}
                        />
                    </div>

                    <SelectBox
                        placeholder="Anasayfa Grafik Türü"
                        items={chartTypeList}
                        setItems={setChartTypeList}
                        selectedItems={selectedChartType}
                        setSelectedItems={setSelectedChartType}
                    />
                    <Toggle
                        leftLabel="Fiyat Görme"
                        isCheck={canSeeMoney}
                        setIsCheck={setCanSeeMoney}
                    />
                </div>
            </form>
        </div>
    )
}

export default ActionSupplier