import ModalLayout from 'components/ModalLayout/ModalLayout'
import React, { useState } from 'react'
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TextInput from 'components/FormElements/TextInput';
import CountInput from 'components/FormElements/CountInput';
import Button from 'components/FormElements/Button';

const schema = yup.object().shape({
    seo_path: yup.string(),
});


function CategoryFilterModal({ closeModal, onGetList, setType, setData }) {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const [productCount, setProductCount] = useState(0)
    const [position, setPosition] = useState(0)
    const [parentId, setParentId] = useState(0)
    const [companyId, setCompanyId] = useState(0)
    const [categoryId, setCategoryId] = useState(0)

    const onSubmit = (data) => {
        let configure = {
            content: {
                status: "",
                position: position.toString(),
                seo_path: data.seo_path,
                parent_id: parentId.toString(),
                company_id: companyId.toString(),
                category_id: categoryId.toString(),
                product_count: productCount.toString()
            }
        }
        onGetList(configure)
        setData(configure)
    }

    return (
        <ModalLayout
            title="Kategori Filtrele"
            closeModal={closeModal}
            hideLine
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-container w-[500px]">
                    <div className="grid grid-cols-2 gap-4">
                        <div className="col-span-2 lg:col-span-1">
                            <CountInput
                                label="Kategori ID"
                                value={categoryId}
                                setValue={setCategoryId}
                            />
                        </div>
                        <div className="col-span-2 lg:col-span-1">
                            <CountInput
                                label="Şirket ID"
                                value={companyId}
                                setValue={setCompanyId}
                            />
                        </div>
                        <div className="col-span-2 lg:col-span-1">
                            <CountInput
                                label="Parent ID"
                                value={parentId}
                                setValue={setParentId}
                            />
                        </div>
                        <div className="col-span-2 lg:col-span-1">
                            <TextInput
                                id="seo_path"
                                name="seo_path"
                                label="Seo Path"
                                innerRef={register("seo_path")}
                            />
                        </div>
                        <div className="col-span-2 lg:col-span-1">
                            <CountInput
                                value={productCount}
                                setValue={setProductCount}
                                label="Ürün Adedi"
                            />
                        </div>
                        <div className="col-span-2 lg:col-span-1">
                            <CountInput
                                value={position}
                                setValue={setPosition}
                                label="Pozisyon"
                            />
                        </div>
                    </div>
                    <div>
                        <hr className='my-3 border-stroke' />
                        <div className="flex justify-end space-x-4">
                            <Button
                                type="tertiary"
                                size="medium"
                                title="Vazgeç"
                                buttonType="button"
                                onClick={() => closeModal()}
                            />
                            <Button
                                type="main"
                                buttonType="submit"
                                size="medium"
                                title="Kaydet"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </ModalLayout>
    )
}

export default CategoryFilterModal