import Badges from 'components/FormElements/Badges'
import { priceFormat } from 'components/Function'
import RightModalLayout from 'components/ModalLayout/RightModalLayout'
import { ArrowRight } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { Fade } from 'react-reveal'

function ProductRequestModal({ closeModal, data }) {

    const [productData, setProductData] = useState()

    useEffect(() => {
        let newData = {}

        async function func() {
            await data.changes.map((item) => {
                newData = ({ ...newData, [item.field]: item.new_value })
            })
        }
        func()
        setProductData(newData)
    }, [])

    return (
        <RightModalLayout
            title="Ürün Detayları"
            closeModal={closeModal}
            when={data}
        >
            <Fade delay={150} duration="1000">
                <div className="flex flex-col space-y-4">
                    <div className="grid grid-cols-12">
                        <div className="col-span-3">
                            <img src={productData?.large_image ? productData?.large_image : "/dummyProduct.png"} width={140} height={140}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = '/dummyProduct.png';
                                }} />
                        </div>
                        <div className="col-span-9 flex flex-col justify-between pl-5">
                            <div>
                                <p className="font-semibold text-black-river-falls-700">{productData?.product_name}</p>
                            </div>
                            <div className="flex justify-between w-full items-center">
                                <div className="flex flex-col space-y-1">
                                    <p className="text-black-river-falls-600 text-sm font-semibold">Fiyat</p>
                                    <h5 className="text-slime-girl-500 font-semibold text-xl">{priceFormat(productData?.price?.SCH)} ₺</h5>
                                </div>
                                {!productData?.is_delist &&
                                    <div className="rounded-full bg-slime-girl-100 px-3 py-1 flex space-x-1 items-center">
                                        <Badges
                                            status="success"
                                            size="small"
                                            hideBorder
                                        />
                                        <p className="text-sm text-slime-girl-600">Aktif</p>
                                    </div>
                                }
                                {productData?.is_delist &&
                                    <div className="rounded-full bg-coral-red-100 px-3 py-1 flex space-x-1 items-center">
                                        <Badges
                                            status="error"
                                            size="small"
                                            hideBorder
                                        />
                                        <p className="text-sm text-coral-red-600">Pasif</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-2">
                        <div className="col-span-4 rounded-md border border-black-river-falls-200 px-2 py-2">
                            <p className="text-black-river-falls-600 font-semibold text-sm">Marka</p>
                            <p className="text-sm text-black-river-falls-600">{productData?.brand}</p>
                        </div>
                        <div className="col-span-4 rounded-md border border-black-river-falls-200 px-2 py-2">
                            <p className="text-black-river-falls-600 font-semibold text-sm">Barkod</p>
                            <p className="text-sm text-black-river-falls-600">{productData?.barcode}</p>
                        </div>
                        <div className="col-span-4 rounded-md border border-black-river-falls-200 px-2 py-2">
                            <p className="text-black-river-falls-600 font-semibold text-sm">Ürün Kodu</p>
                            <p className="text-sm text-black-river-falls-600">{productData?.default_code}</p>
                        </div>
                    </div>
                    <div className="flex flex-col space-y-1">
                        <p className="text-black-river-falls-600 font-semibold text-sm">Kategori</p>
                        <div className="flex space-x-1">
                            {productData?.category?.split("///")?.map((item, index) => {
                                return (
                                    <div key={index} className="flex space-x-1 items-center">
                                        {index !== 0 &&
                                            <ArrowRight size={12} color="#475569" />
                                        }
                                        <p className="text-black-river-falls-600 text-sm">
                                            {item}
                                        </p>
                                    </div>
                                )
                            })

                            }
                        </div>
                    </div>
                    <hr className=' border-stroke' />
                    <div className="flex flex-col space-y-1">
                        <p className="text-black-river-falls-600 font-semibold text-sm">Faydalar</p>
                        <p className="text-black-river-falls-600 text-sm" dangerouslySetInnerHTML={{ __html: productData?.benefits }}></p>
                    </div>
                    <hr className='border-stroke' />
                    <div className="flex flex-col space-y-1">
                        <p className="text-black-river-falls-600 font-semibold text-sm">Açıklama</p>
                        <p className="p-0 text-sm text-black-river-falls-600" dangerouslySetInnerHTML={{ __html: productData?.description }}></p>
                    </div>
                    <hr className=' border-stroke' />
                    <div className="flex flex-col space-y-1">
                        <p className="text-black-river-falls-600 font-semibold text-sm">Kullanım</p>
                        <p className="text-sm text-black-river-falls-600" dangerouslySetInnerHTML={{ __html: productData?.implementation }}></p>
                    </div>
                    <hr className=' border-stroke' />
                    <div className="flex flex-col space-y-1">
                        <p className="text-black-river-falls-600 font-semibold text-sm">Ürün Adresi</p>
                        <a href={productData?.main_url} target="_blank">
                            <p className="text-sm text-black-river-falls-600 hover:text-porcelain-rose-500">{productData?.main_url}</p>
                        </a>
                    </div>
                </div>
            </Fade>
        </RightModalLayout>
    )
}

export default ProductRequestModal