// import Button from 'components/FormElements/Button'
// import Tab from 'components/FormElements/Tab'
// import Loading from 'components/Loading/Loading'
// import RightModalLayout from 'components/ModalLayout/RightModalLayout'
// import ProductDetailModal from 'components/Product/ProductDetailModal'
// import ProductTable from 'components/Product/ProductTable'
// import { FilterSearch } from 'iconsax-react'
// import React, { useEffect, useState } from 'react'
// import { getBrandList, getProductFilterList, getProductList, getProductListByBrand } from 'services/allServices'
// import { useAuthStore } from 'store/AuthStore'
// import { useExcel } from 'hooks/useExcel'
// import Paginate from 'components/FormElements/Paginate'
// import SearchBoxInput from 'components/FormElements/SearchBox'
// import ProductListFilterModal from 'components/Product/ProductListFilterModal'
// import { useGlobalStore } from 'store/GlobalStore'
// import EmptyData from 'components/EmptyData/EmptyData'
// import { CloseCircle } from 'iconsax-react'
// import { Navigate, Router, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// function ProductList() {
//     const [productList, setProductList] = useState([])
//     const [tabLoading, setTabLoading] = useState(true)
//     const [tableLoading, setTableLoading] = useState(true)
//     const [brandList, setBrandList] = useState()
//     const [selectedBrand, setSelectedBrand] = useState("all")
//     const [listType, setListType] = useState("all")
//     const [activeAccount] = useAuthStore((state) => [state.activeAccount]);
//     const [setPaginateTotal, visibleCount] = useGlobalStore((state) => [state.setPaginateTotal, state.visibleCount]);
//     const [onDownloadExcel] = useExcel()
//     const [tableData, setTableData] = useState()
//     const [searchParams, setSearchParams] = useSearchParams();
//     const navigate = useNavigate()
//     const location = useLocation()

//     const [openDetailModal, setOpenDetailModal] = useState(false)
//     const [openFilterModal, setOpenFilterModal] = useState(false)

//     const [filterData, setFilterData] = useState()

//     const onGetProductList = (type, data) => {
//         setTableLoading(true)
//         if (type ? type !== "filter" : listType === "all") {
//             if (selectedBrand === "all") {
//                 getProductList(visibleCount, searchParams.get("p")).then((res) => {
//                     if (res.status !== "brand_not_found") {
//                         setProductList(res?.product_list)
//                         setTableLoading(false)
//                         setPaginateTotal(res.total_product_count)
//                     }
//                 }).catch(() => {
//                     setTableLoading(false)
//                 })
//             }
//             else {
//                 getProductListByBrand(selectedBrand, visibleCount, searchParams.get("p")).then((res) => {
//                     setProductList(res.product_list)
//                     setTableLoading(false)
//                     setPaginateTotal(res.total_product_count)
//                 })
//             }
//         }
//         else {
//             let configure = {
//                 content: {
//                     brand_id: selectedBrand !== "all" ? selectedBrand : null,
//                     price_min: data ? data.price_min : (filterData.price_min ? filterData.price_min : 0),
//                     price_max: data ? data.price_max : (filterData.price_max ? filterData.price_max : 0),
//                 }
//             }
//             getProductFilterList(10, 0, configure).then((res) => {
//                 setProductList(res.product_list)
//                 setTableLoading(false)
//                 setPaginateTotal(res.total_count)
//             })
//         }
//     }

//     const onGetBrandList = () => {
//         getBrandList().then((res) => {
//             setBrandList(res.brandlist)
//             setTabLoading(false)
//         })
//     }

//     useEffect(() => {
//         onGetBrandList()
//     }, [])

//     useEffect(() => {

//         onGetProductList()

//     }, [selectedBrand, visibleCount, searchParams.get("p")])

//     console.log(searchParams.get("p"));

//     return (
//         <div className="flex flex-col space-y-4">
//             {tabLoading ?
//                 <Loading />
//                 :
//                 <Tab data={brandList} selectedTab={selectedBrand} setSelectedTab={(e) => setSelectedBrand(e)} />
//             }
//             <div className="white-container">

//                 <div className="flex justify-between items-center pb-4">
//                     <h4 className="text-xl text-black-river-falls-700 font-semibold">Ürün Listesi</h4>
//                     <div className="flex space-x-2 items-center">
//                         <div>
//                             <SearchBoxInput
//                                 label="Ürün ara"
//                             />
//                         </div>
//                         <Button
//                             type="tertiary"
//                             title="Filtrele"
//                             size="medium"
//                             iconLeft={<FilterSearch className="mr-1" size={20} />}
//                             buttonType="button"
//                             onClick={() => setOpenFilterModal(true)}
//                         />
//                         <Button
//                             size="medium"
//                             title="Excel Aktar"
//                             type="main-success-button"
//                             buttonType="button"
//                             onClick={() => {
//                                 onDownloadExcel("Ürünler", tableData)
//                             }}
//                         />
//                     </div>
//                 </div>
//                 <div className="flex flex-col space-y-2 py-2">
//                     {filterData &&
//                         <div className="flex justify-end items-center text-xs text-black-river-falls-400 space-x-2">
//                             <p>Filtre:</p>
//                             {filterData?.price_min ?
//                                 <p>{filterData?.price_min ? "Minimum Fiyat: " + filterData?.price_min : ""}</p>
//                                 :
//                                 null
//                             }
//                             {filterData?.price_max ?
//                                 <p>{filterData.price_max ? "Maksimum Fiyat: " + filterData?.price_max : ""}</p>
//                                 :
//                                 null
//                             }
//                             <div className="flex items-center space-x-1 text-xs text-porcelain-rose-400 pl-5 cursor-pointer" onClick={() => {
//                                 setListType("all")
//                                 onGetProductList("all")
//                                 setFilterData()
//                             }}>
//                                 <p>Filtreyi temizle</p>
//                                 <CloseCircle size={20} />
//                             </div>
//                         </div>
//                     }
//                     {tableLoading ?
//                         <Loading />
//                         :
//                         productList?.length > 0 ?
//                             <ProductTable data={productList} openDetailModal={(e) => setOpenDetailModal(e)} setTableData={setTableData} />
//                             :
//                             <EmptyData />
//                     }
//                     {!tableLoading &&
//                         <Paginate
//                             multiplier={3}
//                         />
//                     }
//                 </div>
//                 {openDetailModal &&
//                     <ProductDetailModal
//                         closeModal={() => setOpenDetailModal(false)}
//                         data={openDetailModal}
//                     />
//                 }
//                 {openFilterModal &&
//                     <ProductListFilterModal
//                         closeModal={() => setOpenFilterModal(false)}
//                         action={(e) => {
//                             setOpenFilterModal(false)
//                             setListType("filter")
//                             onGetProductList("filter", e)
//                         }}
//                         setFilterData={setFilterData}
//                     />
//                 }
//             </div>
//         </div>
//     )
// }

// export default ProductList
import React from 'react'

function ProductList() {
  return (
    <div>ProductList</div>
  )
}

export default ProductList