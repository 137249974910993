import Button from 'components/FormElements/Button'
import Paginate from 'components/FormElements/Paginate'
import Loading from 'components/Loading/Loading'
import DownloadedReportsTable from 'components/Supplier/DownloadedReportsTable'
import { useExcel } from 'hooks/useExcel'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getSupplierExcelLog } from 'services/allServices'
import { useGlobalStore } from 'store/GlobalStore'

function DownloadedReports() {

    const [onDownloadExcel] = useExcel()
    const [tableData, setTableData] = useState()
    const [loading, setLoading] = useState(true)

    const [downloadedReportList, setDownloadedReportList] = useState()

    const [setPaginateTotal, visibleCount] = useGlobalStore((state) => [state.setPaginateTotal, state.visibleCount]);

    const [searchParams, setSearchParams] = useSearchParams()

    const onGetDownloadedReportList = () => {
        setLoading(true)
        setDownloadedReportList()
        getSupplierExcelLog(visibleCount, searchParams.get("p")).then((res) => {
            if (res?.status !== "record_not_found") {
                setDownloadedReportList(res.log_list)
                setPaginateTotal(res.count)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        onGetDownloadedReportList()
    }, [visibleCount, searchParams.get("p")])

    return (
        <div className="white-container">
            <div className="flex items-center justify-between pb-3">
                <div className="flex space-x-2">
                </div>
                <div className="flex space-x-2">
                    <Button
                        size="medium"
                        title="Excel Aktar"
                        type="main-success-button"
                        buttonType="button"
                        onClick={() => {
                            onDownloadExcel("Yöneticiler", tableData)
                        }}
                    />
                </div>
            </div>
            {loading ?
                <div className="lg:h-[80px]">
                    <Loading width={50} height={50} />
                </div>
                :
                downloadedReportList &&
                <DownloadedReportsTable
                    data={downloadedReportList}
                    setTableData={setTableData}
                />
            }
            <div className="py-4">
                <Paginate />
            </div>

        </div>
    )
}

export default DownloadedReports