import Button from 'components/FormElements/Button'
import PasswordInput from 'components/FormElements/PasswordInput'
import { TickSquare } from 'iconsax-react'
import { TickCircle } from 'iconsax-react'
import React from 'react'

function StaffPassword() {
    return (
        <>
      
        </>
    )
}

export default StaffPassword