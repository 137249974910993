import React from 'react'

function Badges({
  text,
  status,
  size,
  hideBorder
}) {
  return (
    <div className="">
      <div
        className={`
      ${status && (status === "error" && "bg-coral-red-500") ||
          (status === "success" && "bg-slime-girl-500") ||
          (status === "attention" && "bg-fall-gold-500") ||
          (status === "information" && "bg-glitter-lake-500") ||
          (!status && "bg-black-river-falls-400")
          } 
          ${text ? "py-1 px-2 w-auto text-center" :
          (size === "xlsmall" && "p-[2px]") ||
            (size === "xsmall" && "p-[3px]") ||
            (size === "small" && "p-1") ||
            (size === "regular" && "p-[5px]") ||
            (size === "large" && "p-1.5")} 
      ${!size && "p-2"}  ${!hideBorder && "border-2 border-white"} rounded-full `}
      >
        <p className="text-white font-bold text-xs">{text}</p>
      </div>
    </div>
  )
}

export default Badges