import CheckBox from 'components/FormElements/CheckBox';
import React from 'react'
import { useSortBy, useTable, useRowSelect, useResizeColumns, useBlockLayout, useFlexLayout } from 'react-table';
import { TableCheckbox } from './TableCheckBox';

function Table({ columns, data, isCheckbox, tableRef }) {

    const initiallySelectedRows = React.useMemo(() => new Set(["1"]), [])
    const defaultColumn = React.useMemo(
        () => ({
            minWidth: 30,
            width: 150,
            maxWidth: 500,
        }),
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        toggleAllRowsSelected,
        selectedFlatRows,
        state: { selectedRowPaths },
    } =
        useTable({
            columns, data, defaultColumn,
            initialState: {
                selectedRowPaths: initiallySelectedRows
            },

        }, useSortBy, useResizeColumns, useFlexLayout, useRowSelect,
            (hooks) => {
                if (isCheckbox) {
                    hooks.visibleColumns.push((columns) => {
                        return [
                            {
                                id: "selection",
                                groupByBoundary: true,
                                Header: ({ getToggleAllRowsSelectedProps }) => (
                                    <div>
                                        <TableCheckbox
                                            // setCheckList={setCheckList}
                                            // data={data}
                                            // checkList={checkList}
                                            // id={-1}
                                            {...getToggleAllRowsSelectedProps()}
                                        />
                                    </div>
                                ),
                                // The cell can use the individual row's getToggleRowSelectedProps method
                                // to the render a Checkbox
                                Cell: ({ row }) => (
                                    <div>
                                        <TableCheckbox
                                            // setCheckList={setCheckList}
                                            // data={data}
                                            // checkList={checkList}
                                            // id={row.original.id}
                                            {...row.getToggleRowSelectedProps()}
                                        />
                                    </div>
                                ),
                            },
                            ...columns,
                        ];
                    });
                }
            }
        );
    return (
        <div className="overflow-auto customScroll">
            <table ref={tableRef} {...getTableProps()} className="w-full overflow-x-hidden ">
                <thead className="overflow-x-hidden">
                    {
                        headerGroups?.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} className=" bg-black-river-falls-200 text-black-river-falls-600 text-left text-sm ">
                                {
                                    headerGroup.headers.map(column => (

                                        <th {...column.getHeaderProps()} className="px-2 py-3.5 flex items-center relative ">
                                            {
                                                column.render('Header')}
                                            {column.render("Header")?.props?.name !==
                                                "tableHeaderCheck" && (
                                                    <span {...column.render("Header")?.props?.name !== "tableHeaderCheck" &&
                                                    column.getSortByToggleProps()} className="flex items-center h-full ml-2">
                                                        {column.isSorted ? (
                                                            column.isSortedDesc ? (
                                                                <svg
                                                                    className=""
                                                                    width="10" height="6" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4 4L3.49691e-07 -6.99382e-07L8 0L4 4Z" fill="#94A3B8" />
                                                                </svg>
                                                            ) : (
                                                                <svg
                                                                    className=""
                                                                    width="10" height="6" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4 0L8 4H0L4 0Z" fill="#94A3B8" />
                                                                </svg>
                                                            )
                                                        ) : (
                                                            <div className="flex flex-col space-y-2">
                                                                <svg
                                                                    className=""
                                                                    width="10" height="6" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4 0L8 4H0L4 0Z" fill="#94A3B8" />
                                                                </svg>
                                                                <svg
                                                                    className=""
                                                                    width="10" height="6" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4 4L3.49691e-07 -6.99382e-07L8 0L4 4Z" fill="#94A3B8" />
                                                                </svg>
                                                            </div>
                                                        )}
                                                    </span>
                                                )}
                                            <button
                                                {...column.getResizerProps()}
                                                className={`resizer  ${column.isResizing ? "isResizing" : ""
                                                    }`}
                                            />
                                        </th>
                                    ))}
                            </tr>
                        ))}
                </thead>
                { }
                <tbody className="overflow-x-hidden" {...getTableBodyProps()}>
                    {
                        rows?.map((row, index) => {
                            prepareRow(row)
                            return (

                                <tr {...row.getRowProps()} className="bg-white rounded-md duration-300 text-black-river-falls-500 hover:bg-black-river-falls-100 font-medium border-b border-black-river-falls-200 text-sm"  >
                                    {
                                        row.cells?.map(cell => {

                                            return (
                                                <td {...cell?.getCellProps()} className="py-4 px-2 break-words ">
                                                    {
                                                        cell?.render('Cell')}

                                                </td>
                                            )
                                        })}
                                </tr>
                            )
                        })}
                </tbody>
            </table>
        </div>
    )
}

export default Table