import { SearchNormal } from "iconsax-react";
import React, { useRef } from "react";


function SearchBoxInput({
  label,
  name,
  value,
  onChange,
  type = "text",
  error,
  id,
  success,
  warning,
  required,
  disabled,
  inputState = "default",
  errorStyle,
  size,



}) {
  let defaultInputStyle = `w-full px-2 font-regular  rounded-md  text-black-river-falls-500 peer focus:pt-3 pt-3 placeholder-transparent`;
  let iconStyle = `absolute text-black-river-falls-400 top-3     right-2`
  let formStyle = `w-full h-11 relative `;
  let searchSize = `${size === "small" ? "h-11 w-24" : size === "medium" ? "h-11 w-32" : size === "large" ? "h-11 w-40" : "h-11 w-52"}`;
  let labelStyle = `${"-top-1 text-xs top-1 text-black-river-falls-400 absolute left-0.5 px-1 duration-300 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2.5 peer-focus:top-1 peer-focus:text-sPink peer-focus:text-xs cursor-pointer duration-300"
    }`;
  let inputStyle = `${defaultInputStyle} ${formStyle} ${searchSize}
  ${inputState === "default" && !disabled
      ? "border border-ring-black-river-falls-200 focus:outline-none focus:ring-1 focus:ring-black-river-falls-400"
      : disabled
        ? "cursor-not-allowed border-none bg-black-river-falls-200  focus:ring-none"
        : ""
    }
  ${inputState === "error" && "border-none focus:outline-none focus:ring-1 focus:ring-coral-red-500"

    }
  ${inputState === "success" && "border-none focus:outline-none focus:ring-1 focus:ring-slime-girl-500"

    }
  ${inputState === "warning" && "border-none focus:outline-none focus:ring-1 focus:ring-fall-gold-500"

    }
  ${inputState === "primary" && "border-none focus:outline-none focus:ring-1 focus:ring-glitter-lake-500"

    }
  `



  let inputRef = useRef(null);
  return (
    <div className={formStyle}>
      <div>
        <input

          ref={inputRef}
          type={type}
          className={inputStyle}
          id={id}
          name={name}
          placeholder={label}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          required={required}
          disabled={disabled}
        />
        <label
          className={labelStyle}
          onClick={() => {
            inputRef.current.focus();
          }}
          htmlFor={name}
        >
          {label}
        </label>

        <div className={iconStyle} htmlFor={name}>
          <SearchNormal size={20} />
        </div>

      </div>

      {error && <div className={"text-coral-red-500 text-xs "}>{error}</div>}
      {success && <div className={"text-slime-girl-500 text-xs "}>{success}</div>}
      {warning && <div className={"text-fall-gold-500 text-xs "}>{warning}</div>}
    </div>
  );
}

export default SearchBoxInput;
