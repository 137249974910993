import Button from 'components/FormElements/Button'
import ProfileImage from 'components/FormElements/ProfileImage'
import TextInput from 'components/FormElements/TextInput'
import StaffAvatar from 'components/Staff/ActionStaff/StaffAvatar'
import StaffNotification from 'components/Staff/ActionStaff/StaffNotification'
import StaffPassword from 'components/Staff/ActionStaff/StaffPassword'
import StaffProfile from 'components/Staff/ActionStaff/StaffProfile'
import { TickSquare } from 'iconsax-react'
import React from 'react'
import { useParams } from 'react-router'

function ActionStaff() {
    const params = useParams()

    return (
        <div className="flex flex-col space-y-4">
            {/* <div className="white-container">
                <StaffAvatar />
            </div> */}
            <div className="white-container">
                <StaffProfile />
            </div>
            {/* <div className="white-container">
                <StaffPassword />
            </div> */}
            {/* <div className="white-container">
                <StaffNotification />
            </div> */}
        </div>
    )
}

export default ActionStaff