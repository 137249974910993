import React from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

function SideBar({ data }) {
    const location = useLocation()
    return (
        <div className="bg-white px-2 py-4 w-[240px] rounded-md unselectable">
            <h6 className="text-black-river-falls-700 font-semibold text-lg px-2">{data?.title}</h6>
            <div className="flex flex-col space-y-1 pt-2">
                {data?.altItem?.map((item, index) => {
                    return <Link key={index} to={item.url ? item.url : "/"}>
                        <div className={`${location.pathname === item.url ? "text-porcelain-rose-500 bg-porcelain-rose-100" : "text-black-river-falls-500 hover:text-porcelain-rose-500  hover:bg-porcelain-rose-100"} py-3 rounded-md px-2 `}>
                            <p className=" text-sm font-semibold ">{item.title}</p>
                        </div>
                    </Link>
                })}
            </div>
        </div>
    )
}

export default SideBar