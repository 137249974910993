import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { PrivateRoute } from "./private.routes";
import Login from "pages/Login/Login";
import Homepage from "pages/Homepage/Homepage";
import Layout from "layout/Layout";
import { tokenCheck } from "services/allServices";
import { useAuthStore } from "store/AuthStore";
import StaffList from "pages/Staff/StaffList";
import PageLayout from "components/PageLayout/PageLayout";
import { UserAdd } from "iconsax-react";
import ActionStaff from "pages/Staff/ActionStaff";
import StaffDepartment from "pages/Staff/StaffDepartment";
import { AddSquare } from "iconsax-react";
import BrandList from "pages/Brand/BrandList";
import CategoryList from "pages/Category/CategoryList";
import ProductRequestList from "pages/Product/ProductRequestList";
import ProductEditRequestList from "pages/Product/ProductEditRequestList";
import ProductList from "pages/Product/ProductList";
import SupplierList from "pages/Supplier/SupplierList";
import Loading from "components/Loading/Loading";
import DownloadedReports from "pages/Supplier/DownloadedReports";
import SupplierSettings from "pages/Supplier/SupplierSettings";
import ActionSupplier from "pages/Supplier/ActionSupplier";


const browserHistory = createBrowserHistory();

const RouterList = () => {
  const [authTokens, setAuthTokens] = useState();
  const setTokens = (data) => setAuthTokens(data);
  const [count, setCount] = useState(0)
  const [isLoggin, setIsLoggin] = useAuthStore((state) => [
    state.isLoggin,
    state.setIsLoggin,
  ]);

  const staffItem = {
    mainPage: "Yöneticiler",
    pageList: [
      {
        title: "Tüm Yöneticiler",
        url: "/yoneticiler/tum-yoneticiler",
        createButton: true,
        buttonUrl: "/yoneticiler/yonetici-olustur",
        buttonTitle: "Yönetici Oluştur",
        buttonIcon: <UserAdd size={20} color="white" />
      },
      {
        title: "Yönetici Oluştur",
        url: "/yoneticiler/yonetici-olustur",
        createButton: false,
      },
      {
        title: "Yönetici Düzenle",
        url: "/yoneticiler/yonetici-duzenle",
        createButton: false,
      },
      {
        title: "Departmanlar",
        url: "/yoneticiler/departmanlar",
        createButton: true,
        buttonTitle: "Departman Oluştur",
        buttonIcon: <AddSquare size={20} color="white" />,
        buttonAction: true
      }
    ]
  }

  const supplierItem = {
    mainPage: "Tedarikçiler",
    dynamicPage: "/tedarikciler/tedarikci-duzenle/:id",
    pageList: [
      {
        title: "Tüm Tedarikçiler",
        url: "/tedarikciler/tum-tedarikciler",
      },
      {
        title: "Tedarikçi Ayarları",
        url: "/tedarikciler/tedarikci-ayarlari",
      },
      {
        title: "İndirilen Raporlar",
        url: "/tedarikciler/indirilen-raporlar",
      },
      {
        title: "Tedarikçi Düzenle",
        url: "/tedarikciler/tedarikci-duzenle/:id",
      }
    ]
  }

  const brandItem = {
    mainPage: "Markalar",
    pageList: [
      {
        title: "Tüm Markalar",
        url: "/markalar/tum-markalar",
        createButton: false,
      },
    ]
  }

  const categoryItem = {
    mainPage: "Kategoriler",
    pageList: [
      {
        title: "Tüm Kategoriler",
        url: "/kategoriler/tum-kategoriler",
        createButton: false,
      },
    ]
  }

  const productItem = {
    mainPage: "Ürünler",
    dynamicPage: "/urunler/:name/:id",
    pageList: [
      {
        title: "Ürünler",
        url: "/urunler",
        createButton: true,
        buttonUrl: "/urunler/urun-olustur",
        buttonTitle: "Yeni Ürün Oluştur",
      },
      {
        title: "Ürün Detayı",
        url: "/urunler/:name/:id",
        isDynamic: true,
      },
      {
        title: "Ürün Detayı",
        url: "/urunler/:name/:id",
        isDynamic: true,
      },
      {
        title: "Oluşturma Talepleri",
        url: "/urunler/olusturma-talepleri",
      },
      {
        title: "Düzenleme Talepleri",
        url: "/urunler/duzenleme-talepleri",
      }
    ]
  }


  const checkAuth = async () => {
    if (localStorage.getItem("__mp_tk") !== null && count === 0) {
      tokenCheck(localStorage.getItem("__mp_tk"))
        .then((res) => {
          setCount(1)
          if (!res.status) {
            localStorage.clear()
            setIsLoggin(false);
            window.location.pathname = "/login"
          }
        })
        .catch((err) => {
          localStorage.clear()
          setCount(1)
          setIsLoggin(false);
          window.location.pathname = "/login"
        });
    }
    if (!localStorage.getItem("__mp_tk")) {
      setIsLoggin(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);



  return (
    <Router history={browserHistory}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route
            path="/login"
            element={<Login />}
          />
          {/* <Route
            path="/register"
            element={<Register />}
          /> */}
          <Route element={<PrivateRoute />}>
            <Route element={<Layout />}>
              {/* <Route
                path="/form-components"
                element={<FormComponents />}
              /> */}
              <Route
                path={"/"}
                element={<Homepage />}
              />
              <Route element={<PageLayout data={supplierItem} />}>
                <Route
                  path="/tedarikciler"
                >
                  <Route path="" element={<SupplierList />} />
                  <Route path="tum-tedarikciler" element={<SupplierList />} />
                  <Route path="indirilen-raporlar" element={<DownloadedReports />} />
                  <Route path="tedarikci-ayarlari" element={<SupplierSettings />} />
                  <Route path="tedarikci-duzenle/:id" element={<ActionSupplier />} />
                </Route>
              </Route>

              <Route element={<PageLayout data={staffItem} />}>
                <Route
                  path="/yoneticiler"
                >
                  <Route path="" element={<StaffList />} />
                  <Route path="/yoneticiler/tum-yoneticiler" element={<StaffList />} />
                  <Route path="/yoneticiler/yonetici-olustur" element={<ActionStaff />} />
                  <Route path="/yoneticiler/yonetici-duzenle/:id" element={<ActionStaff />} />
                  <Route path="/yoneticiler/departmanlar" element={<StaffDepartment />} />
                </Route>
              </Route>

              <Route element={<PageLayout data={brandItem} />}>
                <Route
                  path="/markalar"
                >
                  <Route path="" element={<BrandList />} />
                  <Route path="/markalar/tum-markalar" element={<BrandList />} />
                  <Route path="/markalar/tum-markalar/:id" element={<BrandList />} />
                </Route>
              </Route>

              <Route element={<PageLayout data={categoryItem} />}>
                <Route
                  path="/kategoriler"
                >
                  <Route path="" element={<CategoryList />} />
                  <Route path="/kategoriler/tum-kategoriler" element={<CategoryList />} />

                </Route>
              </Route>

              <Route element={<PageLayout data={productItem} />}>
                <Route path="/urunler">
                  <Route path="" element={<ProductList />} />
                  <Route path="/urunler/olusturma-talepleri" element={<ProductRequestList />} />
                  <Route path="/urunler/duzenleme-talepleri" element={<ProductEditRequestList />} />
                </Route>
              </Route>

              <Route
                path="*"
                element={<div>404</div>}
              />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};
export default RouterList;
