import Button from 'components/FormElements/Button'
import SearchBoxInput from 'components/FormElements/SearchBox'
import AttentionModal from 'components/Modal/AttentionModal'
import ActionDepartmentModal from 'components/Staff/ActionDepartmentModal'
import StaffDepartmentTable from 'components/Staff/StaffDepartmentTable'
import { successToast } from 'components/Toast/AllToast'
import { FilterSearch } from 'iconsax-react'
import { Setting4 } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import { deleteStaffDepartment, getStaffDepartmentList } from 'services/allServices'

function StaffDepartment({ }) {
    const [staffDepartmentList, setStaffDepartmentList] = useState()

    const [openModal, setOpenModal] = useOutletContext()
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const onGetStaffDepartmentList = () => {
        setStaffDepartmentList()
        getStaffDepartmentList().then((res) => {
            setStaffDepartmentList(res.record)
        })
    }

    const onCloseAttentionModal = () => {
        let id = openDeleteModal
        setOpenDeleteModal(false)
        setOpenModal({ type: "edit", id: id })
    }

    const onDeleteStaffDepartment = (id) => {
        deleteStaffDepartment(id).then(() => {
            successToast("Departman", "Departman başarıyla silindi.")
            setOpenDeleteModal(false)
            onGetStaffDepartmentList()
        })
    }


    useEffect(() => {
        onGetStaffDepartmentList()
    }, [])

    return (
        <div className="white-container">
            <div className="flex justify-between items-center pb-3">
                <div className="flex space-x-2">
            
                </div>
                <div className="flex space-x-2">
                    <SearchBoxInput
                        label="Departman Ara"
                    />
                </div>
            </div>
            {staffDepartmentList &&
                <StaffDepartmentTable
                    data={staffDepartmentList}
                    openEditModal={(id) => setOpenModal({ type: "edit", id: id })}
                    openDeleteModal={(id) => setOpenDeleteModal({ id: id })}
                />
            }
            {openModal &&
                <ActionDepartmentModal
                    closeModal={() => setOpenModal(false)}
                    onGetList={onGetStaffDepartmentList}
                    type={openModal.type}
                    id={openModal.id}
                    onDelete={(v) => {
                        setOpenModal(false)
                        setOpenDeleteModal({ id: v, type: "editdelete" })
                    }}
                />
            }
            {openDeleteModal &&
                <AttentionModal
                    title="Departman Sil"
                    description="Departmanı silmek istediğinize emin misiniz?"
                    secondaryButtonAction={() => openDeleteModal.type === "editdelete" ? onCloseAttentionModal() : setOpenDeleteModal(false)}
                    buttonAction={() => onDeleteStaffDepartment(openDeleteModal.id)}
                    buttonText="Sil"
                    closeModal={() => setOpenDeleteModal(false)}
                />
            }
        </div>
    )
}

export default StaffDepartment