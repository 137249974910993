import Button from 'components/FormElements/Button';
import { CloseCircle } from 'iconsax-react';
import React, { useEffect, useRef } from 'react'
import { Fade } from 'react-reveal';

function RightModalLayout({ title, closeModal, children, hideLine, buttonRightText, buttonRightClick, buttonLeftText, buttonLeftClick, data }) {

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    closeModal(false);
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    return (
        <div className="fixed z-[50] inset-0 overflow-y-auto h-screen overflow-x-hidden" >
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75" />
            </div>
            <div ref={wrapperRef} className='flex items-center justify-end w-full ' >
                <Fade right when={data} duration="400">
                <div className=" p-4 bg-white shadow-xl transform transition-all min-h-screen h-auto w-[600px]" style={{ overflow: "initial" }} >
                    <div className='flex justify-between items-center '>
                        <p className="text-black-river-falls-700 font-semibold text-xl">{title}</p>
                        {closeModal &&
                            <div onClick={() => closeModal()} className="cursor-pointer">
                                <CloseCircle size={24} className="text-black-river-falls-300" />
                            </div>
                        }
                    </div>
                    {!hideLine &&
                        <hr className='my-3 border-stroke' />
                    }
                    <div className="py-2 ">
                        {children}
                    </div>
                </div>
                </Fade>
            </div>

        </div>
    )
}

export default RightModalLayout