import { ArrowDown2 } from 'iconsax-react'
import React, { useEffect, useRef, useState } from 'react'
import { TailSpin } from 'react-loader-spinner'

function Button({ rightIconStyle, leftIconStyle, titleStyle, style, size, onClick, isLoading, disabled = isLoading && true, iconRight, iconLeft, title, type = "main", buttonType }) {

  let defaultStyle = `${!type && "bg-porcelain-rose-500"} flex items-center justify-center z-10 text-sm font-regular text-white rounded-sm  ${style && style}`
  let buttonSize = `${size === "small" ? " px-[12px] py-[8px]" : size === "split-small" ? "px-[8px] py-[12px]" : size === "split-medium" ? "pl-[12px] pr-[5px] py-[12px]" :
    size === "split-large" ? "px-[16px] py-[16px]" : size === "medium" ? "px-[24px] py-[12px]" : size === "large" ? " px-[32px] py-[16px]" : null}`
  let buttonStyle = `${defaultStyle} ${buttonSize} ${titleStyle}

${(type === "main" && !disabled) && "bg-porcelain-rose-500 hover:bg-porcelain-rose-600  text-sm font-regular text-white space-x-1 rounded-lg transition-all duration-300"}
${(type === "main" && disabled) && "bg-black-river-falls-200 text-sm font-regular text-black-river-falls-400 rounded-lg cursor-not-allowed"}

${(type === "secondary" && !disabled) && "bg-porcelain-rose-100 ring-2 ring-inset ring-0  text-porcelain-rose-500 hover:text-coral-red-500 font-regular rounded-lg transition-all duration-300"}
${(type === "secondary" && disabled) && "bg-transparent ring-2 ring-inset ring-black-river-falls-200 text-black-river-falls-400 font-regular rounded-lg cursor-not-allowed"}

${(type === "tertiary" && !disabled) && "!bg-white border ring-0 ring-inset border-black-river-falls-300 hover:border-black-river-falls-500 !text-black-river-falls-500 font-regular rounded-lg transition-all duration-300"}
${(type === "tertiary" && disabled) && "bg-black-river-falls-200  text-black-river-falls-400 font-regular rounded-lg cursor-not-allowed"} 

${(type === "main-alert-button" && !disabled) && "bg-coral-red-500 hover:bg-coral-red-700 text-sm font-regular text-white space-x-1 rounded-lg transition-all duration-300"}
${(type === "main-alert-button" && disabled) && "bg-black-river-falls-200 text-sm font-regular text-black-river-falls-400 rounded-lg cursor-not-allowed"}

${(type === "secondary-alert-button" && !disabled) && "bg-coral-red-100 ring-inset ring-coral-red-500 ring-0 hover:ring-coral-red-500 !text-coral-red-500 hover:bg-coral-red-200 hover:text-coral-red-700 font-regular rounded-lg transition-all duration-300"}
${(type === "secondary-alert-button" && disabled) && "bg-transparent ring-2 ring-inset ring-black-river-falls-300 text-black-river-falls-400 font-regular rounded-lg cursor-not-allowed"}

${(type === "tertiary-alert-button" && !disabled) && "!bg-white ring-2 ring-inset ring-black-river-falls-300 hover:ring-black-river-falls-500 text-black-river-falls-500 font-regular rounded-lg transition-all duration-300"}
${(type === "tertiary-alert-button" && disabled) && "bg-black-river-falls-200  text-black-river-falls-400 font-regular rounded-lg cursor-not-allowed"} 

${(type === "main-warning-button" && !disabled) && "bg-fall-gold-500 hover:bg-fall-gold-700 text-sm font-regular text-white space-x-1 rounded-lg transition-all duration-300"}
${(type === "main-warning-button" && disabled) && "bg-black-river-falls-200 text-sm font-regular text-black-river-falls-400 rounded-lg cursor-not-allowed"}

${(type === "secondary-warning-button" && !disabled) && "bg-transparent ring-2 ring-inset ring-fall-gold-500 hover:ring-fall-gold-700 text-fall-gold-500 hover:text-fall-gold-700 font-regular rounded-lg transition-all duration-300"}
${(type === "secondary-warning-button" && disabled) && "bg-transparent ring-2 ring-inset ring-black-river-falls-300 text-black-river-falls-400 font-regular rounded-lg cursor-not-allowed"}

${(type === "tertiary-warning-button" && !disabled) && "bg-white ring-2 ring-inset ring-black-river-falls-300 hover:ring-black-river-falls-500 text-black-river-falls-500 font-regular rounded-lg transition-all duration-300"}
${(type === "tertiary-warning-button" && disabled) && "bg-black-river-falls-200   text-black-river-falls-400 font-regular rounded-lg cursor-not-allowed"} 

${(type === "main-success-button" && !disabled) && "bg-slime-girl-500 hover:bg-slime-girl-600 text-sm font-regular text-white space-x-1 rounded-lg transition-all duration-300"}
${(type === "main-success-button" && disabled) && "bg-black-river-falls-200 text-sm font-regular text-black-river-falls-400 rounded-lg cursor-not-allowed"}

${(type === "secondary-success-button" && !disabled) && "bg-transparent ring-2 ring-inset ring-slime-girl-500 hover:ring-slime-girl-600 text-slime-girl-500 hover:text-slime-girl-600 font-regular rounded-lg transition-all duration-300"}
${(type === "secondary-success-button" && disabled) && "bg-transparent ring-2 ring-inset ring-black-river-falls-300 text-black-river-falls-400 font-regular rounded-lg cursor-not-allowed"}

${(type === "tertiary-success-button" && !disabled) && "bg-white ring-2 ring-inset ring-black-river-falls-300 hover:ring-black-river-falls-500 text-black-river-falls-500 font-regular rounded-lg transition-all duration-300"}
${(type === "tertiary-success-button" && disabled) && "bg-black-river-falls-200   text-black-river-falls-400 font-regular rounded-lg cursor-not-allowed"} 

${(type === "main-information-button" && !disabled) && "bg-glitter-lake-500 hover:bg-glitter-lake-600 text-sm font-regular text-white space-x-1 rounded-lg transition-all duration-300"}
${(type === "main-information-button" && disabled) && "bg-black-river-falls-200 text-sm font-regular text-black-river-falls-400 rounded-lg cursor-not-allowed"}

${(type === "secondary-information-button" && !disabled) && "bg-transparent ring-2 ring-inset ring-glitter-lake-500 hover:ring-glitter-lake-600 text-glitter-lake-500 hover:text-glitter-lake-600 font-regular rounded-lg transition-all duration-300"}
${(type === "secondary-information-button" && disabled) && "bg-transparent ring-2 ring-inset ring-black-river-falls-300 text-black-river-falls-400 font-regular rounded-lg cursor-not-allowed"}

${(type === "tertiary-information-button" && !disabled) && "bg-white ring-2 ring-inset ring-black-river-falls-300 hover:ring-black-river-falls-500 text-black-river-falls-500 font-regular rounded-lg transition-all duration-300"}
${(type === "tertiary-information-button" && disabled) && "bg-black-river-falls-200   text-black-river-falls-400 font-regular rounded-lg cursor-not-allowed"} 

${(type === "split-button" && !disabled) && "group bg-porcelain-rose-500 text-white hover:bg-porcelain-rose-600 font-regular rounded-lg  transition-all duration-300"}
${(type === "split-button" && disabled) && "bg-black-river-falls-200 text-black-river-falls-400 font-regular rounded-lg  cursor-not-allowed"}

${(type === "split-button-red" && !disabled) && "group bg-coral-red-500 text-white hover:bg-coral-red-600 font-regular rounded-lg  transition-all duration-300"}
${(type === "split-button-red" && disabled) && "bg-black-river-falls-200 text-black-river-falls-400 font-regular rounded-lg  cursor-not-allowed  "}

${(type === "split-button-gold" && !disabled) && "group bg-fall-gold-500 text-white hover:bg-fall-gold-600 font-regular rounded-lg  transition-all duration-300"}
${(type === "split-button-gold" && disabled) && "bg-black-river-falls-200 text-black-river-falls-400 font-regular rounded-lg  cursor-not-allowed"}

${(type === "split-button-green" && !disabled) && "group bg-slime-girl-500 text-white hover:bg-slime-girl-600 font-regular rounded-lg  transition-all duration-300"}
${(type === "split-button-green" && disabled) && "bg-black-river-falls-200 text-black-river-falls-400 font-regular rounded-lg  cursor-not-allowed"}

${(type === "split-button-blue" && !disabled) && "group bg-glitter-lake-500 text-white hover:bg-glitter-lake-600 font-regular rounded-lg  transition-all duration-300"}
${(type === "split-button-blue" && disabled) && "bg-black-river-falls-200 text-black-river-falls-400 font-regular rounded-lg  cursor-not-allowed"}



`
  const [openSplitMenu, setOpenSplitMenu] = useState(false)

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenSplitMenu(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }


  return (
    <div ref={wrapperRef} className="relative">
      <button type={buttonType} onClick={() => {
        onClick && onClick()
        type.split("-")[0] === "split" && setOpenSplitMenu(!openSplitMenu)
      }
      } className={buttonStyle}>
        <div className={leftIconStyle}>{!isLoading && iconLeft && iconLeft}</div>
        {isLoading &&
          <div className="absolute left-1">
            <TailSpin color="#EB6E66" height={20} width={20} />
          </div>
        }
        <div className={titleStyle} >{title && title}</div>
        <div className={rightIconStyle} >{!isLoading &&  iconRight && iconRight}</div>
        {type.split("-")[0] === "split" &&
          <div className="flex ml-5 relative ">
            <div className={`
          ${!type.split("-")[2] && "border-porcelain-rose-600 group-hover:border-porcelain-rose-700"}
          ${type.split("-")[2] === "red" && "border-coral-red-600 group-hover:border-coral-red-700"}
          ${type.split("-")[2] === "gold" && "border-fall-gold-600 group-hover:border-fall-gold-700"}
          ${type.split("-")[2] === "green" && "border-slime-girl-600 group-hover:border-slime-girl-700"}  
          flex border-r mr-1 absolute -left-1 -top-3 h-[48px] duration-300 transition-all`} />
            <ArrowDown2 />
          </div>
        }
      </button>
      {openSplitMenu &&
        <div className="bg-black absolute -bottom-14 h-12 w-full">

        </div>
      }
    </div>
  )
}

export default Button