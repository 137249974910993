import ProfileImage from 'components/FormElements/ProfileImage'
import { successToast } from 'components/Toast/AllToast'
import { Setting2 } from 'iconsax-react'
import { Logout } from 'iconsax-react'
import { ArrowUp2 } from 'iconsax-react'
import { ArrowDown2 } from 'iconsax-react'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { logout } from 'services/allServices'
import { useAuthStore } from 'store/AuthStore'

function TopBarMenu({data}) {
    const [openMenu, setOpenMenu] = useState()
    const navigate = useNavigate()

    const [setIsLoggin] = useAuthStore((state) => [
        state.setIsLoggin,
    ]);

    const onLogOut = () => {
        localStorage.removeItem("__mp_tk")
        logout().then(() => {
            successToast("Çıkış", "Başarıyla çıkış yaptınız.")
            setIsLoggin(false)
            navigate("/login")
        })
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpenMenu(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    return (
        <div className="relative z-40">
            <div className="flex items-center space-x-2 justify-start rounded-full bg-black-river-falls-100 pr-5 pl-1 py-1">
                <ProfileImage size="xlsmall" status="success" name={data.fullname} />
                <p className="text-sm text-black-river-falls-500">{data.fullname?.split(" ")[0] + " " + data.fullname?.split(" ")[1].charAt(0) + "."}</p>
                <div onClick={() => setOpenMenu(!openMenu)}>
                    {!openMenu ?
                        <ArrowDown2 size={12} className="text-black-river-falls-500 cursor-pointer" />
                        :
                        <ArrowUp2 size={12} className="text-black-river-falls-500 cursor-pointer" />
                    }

                </div>
            </div>
            <div className={`${!openMenu ? "opacity-0" : `opacity-100 duration-500 transition-opacity`} `}>
                {openMenu &&
                    <div ref={wrapperRef} className="absolute border border-black-river-falls-500 border-opacity-20 top-10 right-0 bg-white rounded-md shadow-lg flex flex-col space-y-3 px-3 py-3 w-[250px]">
                        <div className="flex items-center space-x-2">
                            <ProfileImage size="xsmall" status="success" name={data.fullname} />
                            <div className="flex flex-col">
                                <p className="text-black-river-falls-500 font-semibold">{data.fullname}</p>
                                <p className="text-xs text-black-river-falls-400">{data.email}</p>
                            </div>
                        </div>
                        <hr className="text-black-river-falls-200" />
                        <div className="flex items-center space-x-2 px-1 py-1 cursor-pointer text-black-river-falls-500">
                            <Setting2 size={20} className="" />
                            <p className="text-sm">Hesap Ayarları</p>
                        </div>
                        <div className="flex items-center space-x-2 px-1 cursor-pointer text-coral-red-500" onClick={() => onLogOut()}>
                            <Logout size={20} className="" />
                            <p className="text-sm">Çıkış Yap</p>
                        </div>
                    </div>
                }
            </div>

        </div>
    )
}

export default TopBarMenu