import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useGlobalStore } from "store/GlobalStore";

function Homepage() {
  const navigate = useNavigate()
  return (
    <div className=' py-10 flex items-center justify-center'>
     
    </div>
  )
}

export default Homepage;
