import React from 'react'

function Toggle({ id, label, isCheck, setIsCheck, style, rightLabel, leftLabel }) {
  return (
    <div className={`${style && style}`}>
      <p className='text-black-river-falls-500 text-sm '>{label}</p>
      <label htmlFor={id} className="flex items-center cursor-pointer">
        {leftLabel &&
          <p className="text-sm text-black-river-falls-500 mr-3">{leftLabel}</p>
        }
        <div className="relative">
          <input type="checkbox" id={id} className="sr-only" onChange={() => setIsCheck(!isCheck)} />
          <div className={`block w-10 h-6 rounded-full ${isCheck ? " bg-slime-girl-500" : "bg-black-river-falls-300"} `}></div>
          <div className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${isCheck && "translate-x-full"}`}></div>
        </div>
          {rightLabel &&
            <p className="text-sm text-black-river-falls-500 ml-3">{rightLabel}</p>
          }
      </label>
    </div>
  )
}

export default Toggle
