import { utils } from 'xlsx'
import { writeFile } from 'xlsx'

export async function selectBoxItem(items, id, text, secondaryText, checked, contentCheck = true, icon, selectedType = false, typeValue, isContent = false, isAllContent = false) {
  let newItems = []
  if (isAllContent) {
    items.map((x, i) => {
      console.log("ggg")
      newItems.push({ id: x.content[id], text: x.content[text], checked: checked, icon: x[icon] })
    })
  }
  else {
    if (selectedType) {
      console.log("hhh")
      items.map((x, i) =>
        newItems.push({ id: x[id], text: x.content[text][0][typeValue], checked: checked, icon: x[icon] })
      )
    }
    else {
      if (contentCheck) {
        console.log("dd")
        items.map((x, i) =>
          newItems.push({ id: x[id], text: x.content[secondaryText] ? x.content[secondaryText] : x.content[text], checked: checked, icon: x.content[icon] })
        )
      }
      else {
        if (!isContent) {
          console.log("sss")
          items.map((x, i) =>
            newItems.push({ id: x[id !== "" ? id : i], text: x[secondaryText] ? x[secondaryText][text] : x[text], checked: checked, icon: x[icon] })
          )
        }
        else {
          console.log("bbb")
          items.map((x, i) =>
            newItems.push({ id: x[id !== "" ? id : i], text: x.content[secondaryText] ? x.content[secondaryText][text] : x.content[text], checked: checked, icon: x.content[icon] })
          )
        }

      }
    }
  }
  return newItems
}


export function priceFormat(price) {
  let newPrice = Number.parseFloat(price).toFixed(2)
  let newFormat = {}
  if (newPrice.length > 6) {
    let firstNumber = newPrice.substring(0, newPrice.length - 6) + "."
    let secondNumber = newPrice.substring(newPrice.length - 6, newPrice.length)
    newFormat = firstNumber + secondNumber.toString().replaceAll(".", ",")
  }
  else {
    newFormat = newPrice.toString().replaceAll(".", ",")
  }
  return newFormat
}

export function exportCSVTOExcel(data, fileName) {
  let ws = utils.json_to_sheet(data);
  let wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "sheet");
  // let buf = write(wb, { bookType: 'xlsx', type: 'buffer' }); // generate a nodejs buffer
  // let str = write(wb, { bookType: 'xlsx', type: 'binary' }); // generate a binary string in web browser
  writeFile(wb, `${fileName}.xlsx`);
}