import Button from 'components/FormElements/Button'
import Paginate from 'components/FormElements/Paginate'
import SearchBoxInput from 'components/FormElements/SearchBox'
import TextInput from 'components/FormElements/TextInput'
import Loading from 'components/Loading/Loading'
import AttentionModal from 'components/Modal/AttentionModal'
import StaffTable from 'components/Staff/StaffTable'
import SupplierStaffTable from 'components/Supplier/SupplierStaffTable'
import SupplierTable from 'components/Supplier/SupplierStaffTable'
import { successToast } from 'components/Toast/AllToast'
import { useExcel } from 'hooks/useExcel'
import { Setting4 } from 'iconsax-react'
import { FilterSearch } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { deleteStaff, getsupplierList, getSupplierList, getSupplierStaffList, supplierLoginAs } from 'services/allServices'
import { useGlobalStore } from 'store/GlobalStore'

function SupplierSettings() {
    const [supplierList, setSupplierList] = useState()
    const [loading, setLoading] = useState(true)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [setPaginateTotal, visibleCount] = useGlobalStore((state) => [state.setPaginateTotal, state.visibleCount]);
    const [tableData, setTableData] = useState()

    const [openLoginAsModal, setOpenLoginAsModal] = useState(false)

    const navigate = useNavigate()

    const [onDownloadExcel] = useExcel()

    const onGetSupplierList = () => {
        setLoading(true)
        setSupplierList()
        getSupplierList().then((res) => {
            setSupplierList(res.category_list)
            setLoading(false)
        })
    }

    const onDeleteStaff = (id) => {
        deleteStaff(id).then((res) => {
            successToast("Yönetici", "Yönetici başarıyla silindi")
            onGetSupplierList()
        })
    }

    const onLoginSupplierAs = async () => {
        await supplierLoginAs(openLoginAsModal.supplier_id, openLoginAsModal.staff_id).then((res) => {
            if (res.access_token) {
                window.open(`https://cat-web.sachane.com/admin-token-check?token=${res.access_token}`, '_blank')
                setOpenLoginAsModal(false)
            }
        })
    }

    useEffect(() => {
        onGetSupplierList()
    }, [])
    return (
        <div className="white-container">
            <div className="flex items-center justify-between pb-3">
                <div className="flex space-x-2">
                    <Button
                        size="medium"
                        title="Excel Aktar"
                        type="main-success-button"
                        buttonType="button"
                        onClick={() => {
                            onDownloadExcel("Yöneticiler", tableData)
                        }}
                    />
                </div>
                <div className="flex space-x-2">

                </div>
            </div>
            {loading ?
                <div className="lg:h-[80px]">
                    <Loading width={50} height={50} />
                </div>
                :
                supplierList &&
                <SupplierStaffTable
                    data={supplierList}
                    onDelete={(v) => setOpenDeleteModal(v)}
                    setTableData={setTableData}
                />
            }
            {openDeleteModal &&
                <AttentionModal
                    title="Yönetici Sil"
                    description="Yöneticiyi silmek istediğinize emin misiniz?"
                    buttonAction={() => onDeleteStaff(openDeleteModal)}
                    buttonText="Sil"
                    closeModal={() => setOpenDeleteModal(false)}
                />
            }
            {openLoginAsModal &&
                <AttentionModal
                    title="Tedarikçi Giriş"
                    description="Bu hesaba giriş yapmak istediğinize emin misiniz?"
                    buttonAction={() => onLoginSupplierAs()}
                    buttonText="Giriş Yap"
                    closeModal={() => setOpenLoginAsModal(false)}
                />
            }
        </div>
    )
}

export default SupplierSettings