import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import React, { useEffect, useRef, useState } from "react";

function CountInput({
    label,
    name,
    value,
    setValue,
    onChange,
    error,
    success,
    warning,
    required,
    disabled,
    valueCount,
    inputState = "default",
    errorStyle,
    icon,
    min,
    max
}) {
    let defaultInputStyle = `w-full px-2 font-regular  rounded-md  text-black-river-falls-500 peer focus:pt-3 pt-3 placeholder-transparent`;
    let iconStyle = `absolute   text-black-river-falls-400 top-0.5  right-2 select-none`;
    let formStyle = `w-full h-11 relative`;
    let labelStyle = `${"-top-1 text-xs top-1 text-black-river-falls-400 absolute left-0.5 px-1 duration-300 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2.5 peer-focus:top-1 peer-focus:text-sPink peer-focus:text-xs cursor-pointer duration-300"}`;
    let inputStyle = `${defaultInputStyle} ${formStyle} 
  ${inputState === "default" && !disabled
            ? "border border-black-river-falls-200 focus:outline-none focus:ring-0 focus:border-black-river-falls-400"
            : disabled
                ? "cursor-not-allowed border-none bg-black-river-falls-200  focus:ring-none"
                : ""
        }
  ${inputState === "error" &&
        "border-none focus:outline-none focus:ring-1 focus:ring-coral-red-500"
        }
  ${inputState === "success" &&
        "border-none focus:outline-none focus:ring-1 focus:ring-slime-girl-500"
        }
  ${inputState === "warning" &&
        "border-none focus:outline-none focus:ring-1 focus:ring-fall-gold-500"
        }
  ${inputState === "primary" &&
        "border-none focus:outline-none focus:ring-1 focus:ring-glitter-lake-500"
        }
  `;

    //   const [limit, setLimit] = useState(value);

    const [selectedMax, setSelectedMax] = useState()
    const [selectedMin, setSelectedMin] = useState()
    const ref = useRef(null)

    const handleCheckNumber = async (e) => {

        setValue(parseInt(e.target.value))

        if (parseInt(e.target.value) > selectedMin && parseInt(e.target.value) < selectedMax) {
            setValue(parseInt(e.target.value))
        }
        else {
            if (parseInt(e.target.value) >= selectedMax) {
                setValue(selectedMax)
            }
            else {
                if (parseInt(e.target.value) <= selectedMin) {
                    setValue(selectedMin)
                }
                else {
                    setValue("")
                }
            }
        }
    }

    useEffect(() => {
        if (max) {
            setSelectedMax(parseInt(max))
        }
        else {
            setSelectedMax(Number.MAX_SAFE_INTEGER)
        }
        if (min) {
            setSelectedMin(parseInt(min))
        }
        else {
            setSelectedMin(0)
        }
    }, [max]);


    let inputRef = useRef(null);
    return (
        <div className={formStyle}>
            <div>
                <input
                    autoComplete="off"
                    ref={inputRef}
                    type="number"
                    className={inputStyle}
                    id={name}
                    name={name}
                    placeholder={label}
                    value={value}
                    onChange={(e) => {
                        onChange && onChange()
                        handleCheckNumber(e)

                    }}
                    required={required}
                    disabled={disabled}
                    min={min}
                    max={max}
                />
                <label
                    className={labelStyle}
                    onClick={() => {
                        inputRef.current.focus();
                    }}
                    htmlFor={name}
                >
                    {label}
                </label>

                <div className={iconStyle} htmlFor={name}>
                    <ArrowUp2
                        onClick={() => {
                            !disabled && setValue(value === "" ? min : value < selectedMax ? parseInt(value) + (valueCount ? valueCount : 1) : selectedMax)
                            inputRef.current.focus()
                          }}
                        className=""
                        size={20}
                        variant="Bold"
                    />
                    <ArrowDown2
                        onClick={() => !disabled && setValue(value === "" ? min : value > selectedMin ? parseInt(value) - (valueCount ? valueCount : 1) : selectedMin)}
                        size={20}
                        variant="Bold"
                        className=""
                    />
                </div>
            </div>

            {error && <div className={"text-coral-red-500 text-xs "}>{error}</div>}
            {success && (
                <div className={"text-slime-girl-500 text-xs "}>{success}</div>
            )}
            {warning && (
                <div className={"text-fall-gold-500 text-xs "}>{warning}</div>
            )}
        </div>
    );
}

export default CountInput;
