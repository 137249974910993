import Button from 'components/FormElements/Button'
import { ArrowRight2 } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { Router, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import slugify from 'slugify'

function PageLayout({children, data, hideBreadcrumb }) {
    const [selectedPage, setSelectedPage] = useState()
    const [openModal, setOpenModal] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    useEffect(() => {
        let selected = {}
        let selectedDynamic = {}
        if (params.id || params.name) {
          
            selected = data.pageList.find(item => item.url === data.dynamicPage)
            selectedDynamic = data.pageList.find(item => item.dynamicPage)
            if(location.pathname.split(selectedDynamic?.dynamicPage).length > 1){
                setSelectedPage(selectedDynamic)
            }
            else{
                setSelectedPage(selected)
            }
        }
        else {
            if (params.action) {
                selected = data?.pageList.find((item) => item.url === location.pathname.split("/").slice(0, location.pathname.split("/").length - 1).join("/"))
                setSelectedPage(selected)
            }
            else {
                selected = data?.pageList.find((item) => item.url === location.pathname)
                setSelectedPage(selected)
                if (!selected) {
                    setSelectedPage(data?.pageList[0])
                }
            }
        }

    }, [location, data])
    return (
        <div className="flex flex-col space-y-4 py-5">
            <div className="flex justify-between items-center rounded-md bg-white px-4 py-4 ">
                <div className="flex flex-col space-y-1">
                    <h4 className="text-2xl text-black-river-falls-700 font-semibold">{selectedPage?.title}</h4>
                    {!hideBreadcrumb &&
                        <div className="flex items-center space-x-1">
                            <div className="flex items-center space-x-1">
                                <Link to="/">
                                    <p className="text-black-river-falls-400 text-xs">Anasayfa</p>
                                </Link>
                                <ArrowRight2 size={12} className="text-black-river-falls-400" />
                            </div>
                            {JSON.stringify()}
                            {data.mainPage !== selectedPage?.title &&
                                <div className="flex items-center space-x-1">
                                    <Link to={"/" + slugify(data?.mainPage.toLowerCase())}>
                                        <p className="text-black-river-falls-400 text-xs">{data?.mainPage}</p>
                                    </Link>
                                    <ArrowRight2 size={12} className="text-black-river-falls-400" />
                                </div>
                            }
                            <div className="flex items-center space-x-1">
                                <Link to={selectedPage?.isDynamic ? location.pathname : selectedPage ? selectedPage.url : "/"}>
                                    <p className="text-black-river-falls-400 text-xs">{selectedPage?.title}</p>
                                </Link>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    {selectedPage?.createButton &&
                        <Button
                            type="main"
                            size="medium"
                            buttonType="button"
                            title={selectedPage?.buttonTitle}
                            iconRight={selectedPage?.buttonIcon}
                            onClick={() => selectedPage.buttonUrl ? navigate(selectedPage.buttonUrl) : selectedPage.buttonAction && setOpenModal(!openModal)}
                        />
                    }
                </div>
            </div>
            <div className="">
                <Outlet
                    context={[openModal, setOpenModal]}
                />
                {children}
            </div>
        </div>
    )
}

export default PageLayout