import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router'
import SideBar from './SideBar/SideBar'
import TopBar from './TopBar/TopBar'

function Layout({ children }) {

    const homeItem = {
        title: "Anasayfa",
        altItem: [
            {
                title: "Test",
                url: "/deneme"
            },
            {
                title: "Test2",
                url: "/deneme2"
            },
            {
                title: "Test3",
                url: "/deneme3"
            },
        ]
    }
    const staffItem = {
        title: "Yöneticiler",
        altItem: [
            {
                title: "Yönetici Listesi",
                url: "/yoneticiler/tum-yoneticiler"
            },
            {
                title: "Yönetici Oluştur",
                url: "/yoneticiler/yonetici-olustur"
            },
            {
                title: "Departmanlar",
                url: "/yoneticiler/departmanlar"
            }
        ]
    }

    const supplierItem = {
        title: "Tedarikçiler",
        altItem: [
            {
                title: "Tedarikçi Listesi",
                url: "/tedarikciler/tum-tedarikciler"
            },
            {
                title: "Tedarikçi Ayarları",
                url: "/tedarikciler/tedarikci-ayarlari"
            },
            {
                title: "İndirilen Raporlar",
                url: "/tedarikciler/indirilen-raporlar"
            },

        ]
    }

    const brandItem = {
        title: "Markalar",
        altItem: [
            {
                title: "Tüm Markalar",
                url: "/markalar/tum-markalar"
            }
        ]
    }

    const categoryItem = {
        title: "Kategoriler",
        altItem: [
            {
                title: "Tüm Kategoriler",
                url: "/kategoriler/tum-kategoriler"
            }
        ]
    }

    const productItem = {
        title: "Ürünler",
        altItem: [
            {
                title: "Ürünler",
                url: "/urunler"
            },
            {
                title: "Oluşturma Talepleri",
                url: "/urunler/olusturma-talepleri"
            },
            {
                title: "Düzenleme Talepleri",
                url: "/urunler/duzenleme-talepleri"
            }
        ]
    }

    const location = useLocation()
    const [item, setItems] = useState(homeItem)

    useEffect(() => {
        let path = location.pathname.split("/")[1]
        setItems()
        if (path === "") {
            setItems(homeItem)
        }
        else if (path === "yoneticiler") {
            setItems(staffItem)
        }
        else if (path === "markalar") {
            setItems(brandItem)
        }
        else if (path === "kategoriler") {
            setItems(categoryItem)
        }
        else if (path === "urunler") {
            setItems(productItem)
        }
        else if (path === "tedarikciler") {
            setItems(supplierItem)
        }
        else {
            setItems(homeItem)
        }

    }, [location])

    return (
        <div className="flex flex-col w-full h-screen overflow-hidden">
            <TopBar />
            <div className="flex h-screen bg-black-river-falls-200 ">
                <div className="px-5 py-5">
                    <SideBar data={item} />
                </div>
                <div className='w-full pr-10 overflow-y-auto bg-background customScroll pb-18'>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Layout
