import { Eye, EyeSlash, PasswordCheck } from "iconsax-react";
import React, { useRef, useState } from "react";

function PasswordInput({
  label,
  name,
  id,
  value,
  onChange,
  error,
  success,
  warning,
  required,
  disabled,
  inputState="default",
  errorStyle,
  icon,
  innerRef
}) {
  let defaultInputStyle = `w-full pr-9 px-2 font-regular  rounded-md  text-black-river-falls-500  peer focus:pt-3 pt-3 placeholder-transparent`;
  let iconStyle = `absolute text-black-river-falls-400 top-3  right-2`
  let formStyle = `w-full h-11 relative`;
  let labelStyle = `${
    " text-xs top-1 text-black-river-falls-400 absolute left-0.5 px-1 duration-300 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2.5 peer-focus:top-1 peer-focus:text-sPink peer-focus:text-xs cursor-pointer duration-300"
  }`;
  let inputStyle = `${defaultInputStyle} ${formStyle} 
  ${
    inputState === "default" && !disabled
      ? "border border-ring-black-river-falls-200 focus:outline-none focus:ring-1 focus:ring-black-river-falls-400"
      : disabled
      ? "cursor-not-allowed border-none bg-black-river-falls-200  focus:ring-none"
      : ""
  }
  ${
    inputState === "error" && "border focus:outline-none focus:ring-0 border-coral-red-500"
      
  }
  ${
    inputState === "success" && "border focus:outline-none focus:ring-0 border-slime-girl-500"
      
  }
  ${
    inputState === "warning" && "border focus:outline-none focus:ring-0 border-fall-gold-500"
      
  }
  ${
    inputState === "primary" && "border focus:outline-none focus:ring-0 border-glitter-lake-500"
      
  }
  `
   
  const [type,setType] = useState("password");

  const toggleType = () => {
    setType(type === "password" ? "text" : "password");
    }



  let inputRef = useRef(null);
  return (
    <div className={formStyle}>
      <div>
        <input
          ref={inputRef}
          type={type ?? "text"}
          className={inputStyle}
          id={id}
          name={name}
          placeholder={label}
          value={value}
          onChange={(e)=>onChange(e.target.value)}
          required={required}
          disabled={disabled}
          {...innerRef}
        />
        <label
          className={labelStyle}
          onClick={() => {
            inputRef.current.focus();
          }}
          htmlFor={name}
        >
          {label}
        </label>
    
          <div onClick={toggleType} className={iconStyle} htmlFor={name}>
            {type==="text" ? <Eye className="cursor-pointer" size={20} /> : <EyeSlash className="cursor-pointer" size={20}/>}
          </div>
      </div>

      {error && <div className={"text-coral-red-500 text-xs mt-1 "}>{error}</div>}
    {success && <div className={"text-slime-girl-500 text-xs mt-1 "}>{success}</div>}
    {warning && <div className={"text-fall-gold-500 text-xs mt-1 "}>{warning}</div>}
    </div>
  );
}

export default PasswordInput;
