import api from "./api";
import qs from "query-string";

/* #region LOGIN */
export async function login(UserDetails) {
    const signInData = {
        grant_type: "password",
        username: UserDetails.username,
        password: UserDetails.password,
        scope: "",
        client_id: "",
        client_secret: "",
    };
    const res = await api.post("company/login", qs.stringify(signInData), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
    api.defaults.headers.Authorization = `Bearer ${res.data.access_token}`;
    return res.data;
}

export async function logout() {
    const res = await api.get(`company/logout`);
    return res.data;
}

export async function tokenCheck(token) {
    const res = await api.get(`company/token-check?token=${token}`);
    return res.data;
}

/* #endregion */

/* #region STAFF */

export async function getStaffList() {
    const res = await api.get("company/staff/list");
    return res.data;
}

export async function getStaffDepartmentList() {
    const res = await api.get("company/department/list");
    return res.data;
}

export async function getStaffOwn() {
    const res = await api.get("company/staff/get/own");
    return res.data;
}

export async function getStaff(id) {
    const res = await api.get(`company/staff/get?staff_id=${id}`);
    return res.data;
}

export async function getStaffDepartment(id) {
    const res = await api.get(`company/department/get?record_id=${id}`);
    return res.data;
}

export async function createStaffDepartment(data) {
    const res = await api.post("company/department/create", data);
    return res.data;
}

export async function updateStaffDepartment(data, id) {
    const res = await api.put(`company/department/update?record_id=${id}`, data);
    return res.data;
}

export async function deleteStaffDepartment(id) {
    const res = await api.delete(`company/department/delete?record_id=${id}`);
    return res.data;
}

export async function createStaff(data) {
    const res = await api.post("company/staff/create", data);
    return res.data;
}

export async function updateStaff(data, id) {
    const res = await api.put(`company/staff/update?staff_id=${id}`, data);
    return res.data;
}

export async function deleteStaff(id) {
    const res = await api.delete(`company/staff/delete?staff_id=${id}`);
    return res.data;
}

/* #endregion StaffEnd*/

/* #region BRAND */

export async function getBrandList(limit = 10, skip = 0) {
    const res = await api.get(`company/brand/list?limit=${limit}&skip=${skip > 0 ? ((skip - 1) * limit) : 0}`);
    return res.data;
}

/* #endregion BRANDEnd*/

/* #region CATEGORY */

export async function getCategoryList(limit = 10, skip = 0) {
    const res = await api.get(`company/category/list?limit=${limit}&skip=${skip > 0 ? ((skip - 1) * limit) : 0}`);
    return res.data;
}

export async function getCategoryFilterList(limit = 10, skip = 0, data) {
    const res = await api.post(`company/category/filter?limit=${limit}&skip=${skip > 0 ? ((skip - 1) * limit) : 0}`, data);
    return res.data;
}

/* #endregion CATEGORYEnd*/

/* #region PRODUCT */

export async function getRequestList(limit = 10, skip = 0) {
    const res = await api.get(`company/product/request/list/all?limit=${limit}&skip=${skip > 0 ? ((skip - 1) * limit) : 0}`);
    return res.data;
}

export async function getRequestListBySupplier(limit = 10, skip = 0, supplier_id) {
    const res = await api.get(`company/product/request/list/by/supplier?supplier_id=${supplier_id}&limit=${limit}&skip=${skip > 0 ? ((skip - 1) * limit) : 0}`);
    return res.data;
}

export async function getRequestListByBrand(limit = 10, skip = 0, brand_id) {
    const res = await api.get(`company/product/request/list/by/brand?brand_id=${brand_id}&limit=${limit}&skip=${skip > 0 ? ((skip - 1) * limit) : 0}`);
    return res.data;
}

export async function getVersionList(limit = 10, skip = 0) {
    const res = await api.get(`company/product/version/unapproved/list/all?limit=${limit}&skip=${skip > 0 ? ((skip - 1) * limit) : 0}`);
    return res.data;
}

export async function getVersionListByProduct(limit = 10, skip = 0, default_code) {
    const res = await api.get(`company/product/version/request/list/by/product?default_code=${default_code}&limit=${limit}&skip=${skip > 0 ? ((skip - 1) * limit) : 0}`);
    return res.data;
}

export async function getVersionListBySupplier(supplier_id, limit = 10, skip = 0,) {
    const res = await api.get(`company/product/version/request/list/by/supplier?supplier_id=${supplier_id}&limit=${limit}&skip=${skip > 0 ? ((skip - 1) * limit) : 0}`);
    return res.data;
}

export async function getVersionListByBrand(brand_id, limit = 10, skip = 0,) {
    const res = await api.get(`company/product/version/request/list/by/brand?brand_id=${brand_id}&limit=${limit}&skip=${skip > 0 ? ((skip - 1) * limit) : 0}`);
    return res.data;
}

export async function getProductVersion(id) {
    const res = await api.get(`company/product/version/request/get?record_id=${id}`);
    return res.data;
}

/* #endregion PRODUCTEnd*/


/* #region SUPPLIER */

export async function updateSupplier(data, id) {
    const res = await api.put(`company/supplier/update?record_id=${id}`, data);
    return res.data;
}

export async function getSupplierList() {
    const res = await api.get("company/supplier/list?limit=1000&skip=0");
    return res.data;
}

export async function getSupplier(id) {
    const res = await api.get(`company/supplier/get?record_id=${id}`);
    return res.data;
}

export async function getSupplierStaffList(limit = 1000, skip = 0) {
    const res = await api.get(`company/supplier/staff/list?limit=${limit}&skip=${skip > 0 ? ((skip - 1) * limit) : 0}`);
    return res.data;
}

export async function supplierLoginAs(supplierId, staffId) {
    const res = await api.get(`company/las?supplier_id=${supplierId}&supplier_staff_id=${staffId}`);
    return res.data;
}

export async function getSupplierExcelLog(limit = 10, skip = 0) {
    const res = await api.get(`company/supplier/excel/log/list/all?limit=${limit}&skip=${skip > 0 ? ((skip - 1) * limit) : 0}`);
    return res.data;
}

/* #endregion SUPPLIEREnd*/