import { CloseSquare } from 'iconsax-react'
import { ArrowDown2 } from 'iconsax-react'
import { SearchNormal } from 'iconsax-react'
import React, { useEffect, useRef, useState } from 'react'
import CheckBox from './CheckBox'

function SelectBox({
  label,
  type,
  items,
  setItems,
  selectedItems,
  setSelectedItems,
  defaultSelect,
  style,
  search,
  placeholder,
  requiredIcon,
  onChange,
  paginate = false,
  disable
}) {

  const [enter, setEnter] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [searchInput, setSearchInput] = useState("")
  const selectItem = (item) => {
    if (onChange) {
      onChange(item)
    }
    let newFilteredResult = []
    if (type === "checkbox") {
      items.map((x) => {
        if (x.id !== item.id) {
          newFilteredResult.push(x)
        } else {
          newFilteredResult.push({ ...item, checked: !item.checked })
        }
      })
      setItems(newFilteredResult)

      if (!selectedItems.find((text) => text?.id === item.id)) {
        setSelectedItems([...selectedItems, item])
      }
      else {
        setSelectedItems(selectedItems.filter(x => x.id !== item.id));
      }
    }
    else {
      setSelectedItems(item)
      setOpenMenu(false)
      setEnter(!enter)
    }
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenMenu(false);
          setEnter(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    if (defaultSelect) {
      setSelectedItems(defaultSelect)
    }
  }
    , [defaultSelect])

  return (
    <div ref={wrapperRef} className={`relative flex flex-col ${style && style}`}>
      <div className={`${enter ? "border-black-river-falls-400 " : "border-black-river-falls-200 "} ${selectedItems ? "py-1" : placeholder ? "py-[9px]" : "py-[21px]"} w-full border relative rounded-md  bg-white flex content-center justify-between cursor-pointer`}
        onClick={() => {
          !disable && setEnter(!enter)
          !disable && setOpenMenu(!openMenu)
        }}>
        <div>
          {selectedItems ?
            <p className="pl-1.5  top-0.5 text-black-river-falls-400 text-xs ">{placeholder && placeholder}</p>
            :
            <p className="pl-1.5 text-black-river-falls-400 ">{placeholder && placeholder}</p>
          }
          {selectedItems &&
            <p className="pl-1.5 text-black-river-falls-500 text-sm ">{selectedItems?.text}</p>
          }
        </div>
        <div className={`${paginate ? "top-1" : "top-3"} absolute right-0 text-black-river-falls-400 px-2`}>
          <ArrowDown2 size={20} />
        </div>
      </div>
      {openMenu &&
        <div className={` ${!paginate ? "top-[50px]" : "bottom-[50px]"} absolute border border-black-river-falls-200 rounded-md bg-white  w-full z-50`}>
          {search &&
            <div className="flex px-2 py-2 relative">
              <input onChange={(e) => setSearchInput(e.target.value)} placeholder="Search" className="py-2 pl-2 pr-6 border rounded-md border-black-river-falls-200 text-black-river-falls-500  outline-none w-full bg-white" />
              <div className="absolute right-4 top-5">
                {searchInput.length > 1 ?
                  <div className="text-black-river-falls-400 cursor-pointer" onClick={() => setSearchInput("")}>
                    <CloseSquare size={20} />
                  </div>
                  :
                  <div className="text-black-river-falls-400">
                    <SearchNormal size={20} />
                  </div>
                }
              </div>
            </div>
          }
          <div className={`${search && " "} max-h-[150px] customScroll rounded-md  bg-white overflow-y-auto `}>
            {items?.filter(x => x.text?.toLowerCase().includes(searchInput.toLowerCase())).map((item, i) =>
              <div className="flex flex-col hover:bg-background-gray cursor-pointer duration-300 " key={i} onClick={() => selectItem(item)} >
                <div className="flex items-center space-x-2 px-2 py-2">
                  {type === "checkbox" &&
                    <CheckBox
                      id={item?.text}
                      name={item?.text}
                      checked={item?.checked}
                      onChange={() => {
                        selectItem(item)
                      }}
                    />
                  }
                  <div className="flex space-x-2 items-center">
                    {item.icon &&
                      <p>{item.icon}</p>
                    }
                    <p className="text-black-river-falls-400 text-sm font-medium">
                      {item?.text}
                    </p>
                  </div>
                </div>
              </div>
            )
            }
          </div>
        </div>
      }
    </div>
  )
}

export default SelectBox