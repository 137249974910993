// import { CallCalling } from "iconsax-react";
// import React, { useEffect, useRef, useState } from "react";
// import { normalizeInput } from "utils/normalizeInput";

// function PhoneInput({
//   label,
//   name,
//   value,
//   setValue,
//   onChange,
//   type="phone",
//   error,
//   success,
//   warning,
//   required,
//   disabled,
//   inputState,
//   errorStyle,
//   icon,
//   phoneId,
//   defaultValue
// }) {
//   let defaultInputStyle = ` w-full px-2 font-regular  rounded-md  text-black-river-falls-500 peer focus:pt-3 pt-3 placeholder-transparent`;
//   let iconStyle = `absolute text-black-river-falls-400 top-2.5  peer-focus:hidden   right-2`
//   let formStyle = ` h-11 relative`;
//   let labelStyle = `${
//     "-top-1 text-xs top-1 text-black-river-falls-400 absolute left-0.5 px-1 duration-300 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2.5 peer-focus:top-1 peer-focus:text-sPink peer-focus:text-xs cursor-pointer duration-300"
//   }`;
//   let inputStyle = `${defaultInputStyle} ${formStyle} 
//   ${
//     inputState === "default" && !disabled
//       ? "border border-ring-black-river-falls-200 focus:outline-none focus:ring-1 focus:ring-black-river-falls-400"
//       : disabled
//       ? "cursor-not-allowed border-none bg-black-river-falls-200  focus:ring-none"
//       : ""
//   }
//   ${
//     inputState === "error" && "border-none focus:outline-none focus:ring-1 focus:ring-coral-red-500"

//   }
//   ${
//     inputState === "success" && "border-none focus:outline-none focus:ring-1 focus:ring-slime-girl-500"

//   }
//   ${
//     inputState === "warning" && "border-none focus:outline-none focus:ring-1 focus:ring-fall-gold-500"

//   }
//   ${
//     inputState === "primary" && "border-none focus:outline-none focus:ring-1 focus:ring-glitter-lake-500"

//   }
//   `

//  const [phoneNumber, setPhoneNumber] = useState('')

// const handleChange = ({ target: { value } }) => {
//   if(value.toString()[0] !== "5"){
//     setPhoneNumber((phoneNumber) => normalizeInput(""))
//   }else{
//     setPhoneNumber((phoneNumber) => normalizeInput(value, phoneNumber))
//     console.log(phoneNumber,"phoneNumber");
//   }};


// //   useEffect(() => {
// // setValue(name,phoneNumber?.replace(/\D/g, ''))
// //   }, [phoneNumber]);


//   useEffect(() => {
//     setPhoneNumber((phoneNumber) => normalizeInput(defaultValue, phoneNumber));
//   }, [defaultValue]);


//   let inputRef = useRef(null);
//   return (
//     <div className={formStyle}>
//       <div>
//         <input
//           ref={inputRef}
//           type="text"
//           className={inputStyle}
//           id={phoneId}
//           name={name}
//           placeholder={label}
//           value={phoneNumber}
//           onChange={handleChange}
//           required={required}
//           disabled={disabled}
//           pattern="[0-9]{3}-[0-9]{2}-[0-9]{2}"
//           maxLength={13}
//           minLength={13}

//           />
//         <label
//           className={labelStyle}
//           onClick={() => {
//             inputRef.current.focus();
//           }}
//           htmlFor={name}
//         >
//           {label}
//         </label>
//         {icon && (value == "" || typeof value === "undefined") &&  (
//           <div className={iconStyle} htmlFor={name}>
//             {icon}
//           </div>
//         )}
//       </div>

//       {error && <div className={"text-coral-red-500 text-xs "}>{error}</div>}
//     {success && <div className={"text-slime-girl-500 text-xs "}>{success}</div>}
//     {warning && <div className={"text-fall-gold-500 text-xs "}>{warning}</div>}
//     </div>
//   );
// }

// export default PhoneInput;
import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import tr from 'react-phone-input-2/lang/tr.json'
import 'react-phone-input-2/lib/style.css'

function InputPhone({
  id,
  name,
  label,
  placeholder,
  value,
  setValue,
  disabled,
  style,
  innerRef,
  search,
  requiredIcon
},
  props,

) {

  const [deleteControl, setDeleteControl] = useState(false)

  return (
    <div className="w-full" >
      <div className={`relative  flex items-center ${style && style}`}>
        <div className="flex flex-col w-full ">

           {label &&
            <label className="absolute bottom-7 left-[45px] z-50 text-xs text-black-river-falls-400">{label && label}</label>
           }

          <PhoneInput
            id={id && id}
            name={name && name}
            value={value && value}
            disabled={disabled && disabled}
            enableSearch={search && search}
            onChange={(phone) => setValue(phone)}
            enableClickOutside={true}
            disableSearchIcon
            localization={tr}
            autoFormat={true}
            countryCodeEditable={false}
            preserveOrder={['tr', 'preferredCountries']}
            country="tr"
            searchPlaceholder="Search"
            placeholder={placeholder && placeholder}
            regions={['america', 'europe', 'asia', 'oceania', 'africa']}
            inputClass="border !pl-12 !pb-1 !w-full !px-3 !py-3 !h-[45px] !rounded-md !text-black-river-falls-500 !font-regular !border-black-river-falls-200 placeholder-black-river-falls-200 !text-sm focus:placeholder-black-river-falls-400 focus:outline-none !focus:border-black-river-falls-400 focus:ring-0 font-medium  disabled:text-textSecondary disabled:bg-border"
            searchClass="w-full px-3 pl-0 rounded-md"
            buttonClass='bg-white !border-black-river-falls-200 rounded-l-md px-1'
            dropdownClass="customScroll"
            {...innerRef}
          />
        </div>
        {/* <div className="absolute right-2 top-8">
                    <i className="ri-phone-line text-xl text-placeholder" />
                </div> */}
      </div>

    </div>
  )
}

export default InputPhone