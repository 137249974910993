import { useWindowSize } from 'hooks/useWindowSize';
import { ArrowLeft2 } from 'iconsax-react';
import { ArrowRight2 } from 'iconsax-react';
import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useSearchParams } from 'react-router-dom';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useGlobalStore } from 'store/GlobalStore';
import SelectBox from './SelectBox';


function Paginate({ total, multiplier }) {

    const history = useNavigate()
    const location = useLocation()
    const [selected, setSelected] = useState(1)
    const { width } = useWindowSize()
    const [searchParams, setSearchParams] = useSearchParams();
    const [setPaginateTotal, paginateTotal, visibleCount, setVisibleCount] = useGlobalStore((state) => [state.setPaginateTotal, state.paginateTotal, state.visibleCount, state.setVisibleCount]);
    const [pageCount, setPageCount] = useState(0);
    const [selectedCount, setSelectedCount] = useState({
        id: 3,
        text: visibleCount,
        checked: false
    })

    const [selectBoxItems, setSelectBoxItems] = useState([
        {
            id: 1,
            text: "1",
            checked: false
        },
        {
            id: 2,
            text: "5",
            checked: false
        },
        {
            id: 3,
            text: "10",
            checked: false
        },
        {
            id: 4,
            text: "20",
            checked: false
        },
        {
            id: 5,
            text: "30",
            checked: false
        },
        {
            id: 6,
            text: "40",
            checked: false
        },
        {
            id: 7,
            text: "50",
            checked: false
        },
        {
            id: 8,
            text: "80",
            checked: false
        },
        {
            id: 9,
            text: "100",
            checked: false
        },
    ])


    const handlePageClick = ({ selected }) => {
        if (selected === 0) {
            if (searchParams.get("name") || searchParams.get("brand")) {
                history(location.pathname + location.search.split("&p")[0])
            }
            else {
                history(location.pathname + location.search.split("?p")[0])
            }

        }
        else {
            history("/")
            if (searchParams.get("name") || searchParams.get("brand")) {
                let newUrl = location.pathname + location.search.split("&p")[0] + `&p=${selected + 1}`
                history(newUrl)
            }
            else {
                history({
                    pathname: location.pathname + location.search.split("?p")[0], search: createSearchParams({
                        p: selected + 1
                    }).toString()
                })
            }

        }
        setSelected(selected + 1)
    }

    const limitChange = (value) => {

        if (value !== "") {
            setVisibleCount(parseInt(value.text))
        } else {
            setVisibleCount()
        }
    }

    useEffect(() => {
        if (searchParams.get("p")) {
            setSelected(parseInt(searchParams.get("p")))
        } else {
            setSelected(1)
        }
    }, [visibleCount])

    useEffect(() => {
        if (visibleCount > 0) {
            setPageCount(paginateTotal / (visibleCount * (multiplier ? multiplier : 1)))
            setVisibleCount(visibleCount)
        }
    }, [paginateTotal, visibleCount]);


    return (
        <div className="flex justify-end">
            <div className="grid grid-cols-12 gap-3">
                <div className="flex items-center justify-end col-span-6 space-x-2 ">
                    <div className="">
                        {/* <Numeric
                            value={visibleCount}
                            setValue={setVisibleCount}
                            onChange={(v) => limitChange(v)}
                            min={0}
                        /> */}
                        <div className="w-[100px]">
                            <SelectBox
                                items={selectBoxItems}
                                style="w-full "
                                setItems={(v) => setSelectBoxItems(v)}
                                onChange={(v) => {
                                    history(location.pathname)
                                    limitChange(v)
                                }}
                                selectedItems={selectedCount}
                                setSelectedItems={(v) => setSelectedCount(v)}
                                paginate={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-end col-span-6">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<ArrowRight2 size={20} />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={width > 500 ? 2 : 1}
                        marginPagesDisplayed={width > 500 ? 3 : 1}
                        pageCount={pageCount}
                        previousLabel={<ArrowLeft2 size={20} />}
                        className="flex items-center justify-center transparent"
                        previousClassName="flex rounded-md bg-white text-black-river-falls-400 w-10 h-10  hover:text-black-river-falls-500 justify-center items-center  font-semibold border border-border"
                        nextClassName="flex rounded-md bg-white text-black-river-falls-400 w-10 h-10  hover:text-black-river-falls-500 justify-center items-center  font-semibold border border-border"
                        pageLinkClassName="bg-white mr-2 ml-2 py-2  w-10 h-10  text-sm rounded-md flex justify-center items-center font-semibold border border-border"
                        pageClassName="flex text-black-river-falls-400 hover:text-black-river-falls-500"
                        breakClassName="text-lightPlaceholderTextColor dark:text-white "
                        activeLinkClassName="bg-white mr-2 ml-2 py-2 text-black-river-falls-500 w-10 h-10  text-sm rounded-md flex justify-center items-center font-semibold border border-border"
                        forcePage={selected - 1}
                    />
                </div>
            </div>
        </div>
    )
}

export default Paginate