import axios from "axios";

export default axios.create({
  //baseURL: "http://192.168.1.201:8050/api/v1",
  baseURL: process.env.REACT_APP_API_URL,


  headers: {
    Authorization: `Bearer ${localStorage.getItem("__mp_tk")}`,
  },
});