import Badges from 'components/FormElements/Badges';
import Table from 'components/Table/Table'
import { Edit } from 'iconsax-react';
import { TrushSquare } from 'iconsax-react';
import moment from 'moment';
import React, { useEffect } from 'react'

function BrandTable({ data, setTableData }) {

    const ignoreHeader = [
        "col3",
        "col5",
        "col6",
        "col8",
        "created_at"
    ]

    function settings(props) {
        return <div className="flex space-x-2 items-center">
            <div className="cursor-pointer" >
                <TrushSquare
                    size={20}
                    className="text-coral-red-500 font-bold"
                />
            </div>
            <div className="cursor-pointer">
                <Edit
                    size={20}
                    variant="Linear"
                    className="text-black-river-falls-500 font-bold"
                />
            </div>
        </div>
    }

    function status(props) {
        return <div>
            {props.value &&
                <div className="flex items-center space-x-1 justify-center bg-slime-girl-100 rounded-full px-1 py-1">
                    <Badges
                        status="success"
                        size="small"
                    />
                    <p className="text-slime-girl-600">Aktif</p>
                </div>
            }
            {/* {props.value !== "A" &&
                <div className="flex items-center space-x-1 justify-center bg-black-river-falls-200 rounded-full px-1 py-1">
                    <Badges
                        size="small"
                    />
                    <p className="text-black-river-falls-400">Pasif</p>
                </div>
            } */}
        </div>
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Marka Adı',
                accessor: 'content.brand',
                Cell: (props) => <a href={"https://sachane.com/" + props.row.original.content.brand_slug + "-c-" + props.row.original.content.category_id  } target="_blank">{props.value}</a>
            },
            {
                Header: 'Kısa Açıklama',
                accessor: 'col3',

            },
            {
                Header: 'Atanan Firma',
                accessor: 'col5',

            },
            {
                Header: 'Durum',
                accessor: 'created_at',
                Cell: status
            },
        ],
        []
    )

    useEffect(() => {

        let excelData = data.map((item, index) => {
            const returnData = {}
            Object.entries(columns).forEach(([key, value]) => {
                let splitAccessor = value.accessor.split(".")
                let tempValue = item
                splitAccessor.forEach((x) => {
                    tempValue = tempValue[x]
                })

                let isAvaible = ignoreHeader.includes(value.accessor)

                if (!isAvaible) {
                    returnData[value.Header] = tempValue
                }

            })
            return returnData
        })
        setTableData(excelData)
    }, [])


    return (
        <div>
            <Table data={data} columns={columns} isCheckbox />
        </div>
    )
}

export default BrandTable