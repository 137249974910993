import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { getStaffOwn, login } from 'services/allServices'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useAuthStore } from 'store/AuthStore'
import PasswordInput from 'components/FormElements/PasswordInput';
import TextInput from 'components/FormElements/TextInput';
import Button from 'components/FormElements/Button';
import { useNavigate } from 'react-router-dom';
import { alertToast, successToast } from 'components/Toast/AllToast';

const schema = yup.object().shape({
  username: yup.string().required("Kullanıcı adı zorunludur."),
  password: yup.string().required("Lütfen şifre giriniz."),
});

function Login() {

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [isLoggin, setIsLoggin, setUserInformations] = useAuthStore((state) => [
    state.isLoggin,
    state.setIsLoggin,
    state.setUserInformations,
  ]);

  const [loginLoading, setLoginLoading] = useState(false)

  const navigate = useNavigate()

  const onLogin = async (data) => {
    if(loginLoading) return
    
    setLoginLoading(true)
    await login(data).then((data) => {
      if (data.access_token) {
        setIsLoggin(true);
        getStaffOwn().then((res) => {
          let configureInfo = {
            fullname: res.content.fullname,
            email: res.content.email,
            is_admin: res.content.is_admin,
          };
          setUserInformations(configureInfo);
          localStorage.setItem(
            "__userInformations",
            JSON.stringify(configureInfo)
          );
        })
        // successToast("", "Login Successfully");
        localStorage.setItem("__mp_tk", data.access_token);
        successToast("", "Giriş Başarılı");
        setLoginLoading(false)
        navigate("/");
      }
    }).catch(err => {
      if (err?.response?.status === 401 || err?.response?.status === 400) {
        alertToast("Giriş", "Kullanıcı adı veya şifre hatalı")
        setLoginLoading(false)
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onLogin)}>
      <div className="grid grid-cols-2 h-screen">
        <div className="col-span-2 lg:col-span-1 bg-black-river-falls-100 flex flex-col justify-center lg:px-0 px-5">
          <div className="flex justify-center space-y-1">
            <div className="flex flex-col space-y-4 w-[500px]">
              <img src="/sachaneLogo.png" className="mb-12" width={213} height={40} />
              <h1 className="text-4xl font-semibold text-black-river-falls-700">Giriş Yap</h1>
              <p className="text-black-river-falls-500">Satıcılar ve müşterileri buluşturan platform.<br />Tüm ürünlerinizi tek yerden yönetin.</p>
              <div className="w-full py-2">
                <TextInput
                  id="username"
                  name="username"
                  label="E-posta"
                  innerRef={register("username")}
                  error={errors.username?.message}
                  inputState={errors.password?.message ? "error" : "default"}
                  inputMode="email"
                />
              </div>
              <div className="py-2">
                <PasswordInput
                  id="password"
                  name="password"
                  label="Parola"
                  innerRef={register("password")}
                  error={errors.password?.message}
                  inputState={errors.password?.message ? "error" : "default"}
                />
              </div>
              <div className="w-full">
                <Button
                  type="main"
                  title="Giriş Yap"
                  size="large"
                  style="w-full"
                  isLoading={loginLoading}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 lg:block hidden bg-loginBackground bg-cover bg-no-repeat pl-20 pt-32">
          <div className="flex flex-col space-y-5 h-full justify-between">
            <h2 className="text-black-river-falls-700 text-5xl font-semibold">Satıcılar ve müştelerileri <br /> buluşturan platform</h2>
            <div className="w-96">
              <p className="text-black-river-falls-500 text-xl">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque amet pharetra a eget sociis ac ornare.</p>
            </div>
            <div className="">
              <img src="/homePicture.png" />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Login
