import Button from 'components/FormElements/Button'
import SearchBoxInput from 'components/FormElements/SearchBox'
import Loading from 'components/Loading/Loading'
import AttentionModal from 'components/Modal/AttentionModal'
import StaffTable from 'components/Staff/StaffTable'
import { successToast } from 'components/Toast/AllToast'
import { useExcel } from 'hooks/useExcel'
import React, { useEffect, useState } from 'react'
import { deleteStaff, getStaffList } from 'services/allServices'
import { useGlobalStore } from 'store/GlobalStore'

function StaffList() {
    const [staffList, setStaffList] = useState()
    const [loading, setLoading] = useState(true)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [setPaginateTotal, visibleCount] = useGlobalStore((state) => [state.setPaginateTotal, state.visibleCount]);
    const [tableData, setTableData] = useState()

    const [onDownloadExcel] = useExcel()

    const onGetStaffList = () => {
        setStaffList()
        getStaffList().then((res) => {
            setStaffList(res)
            setLoading(false)
            if (res.count) {
                setPaginateTotal(res.count)
            }
            else {
                setPaginateTotal(1)
            }
        })
    }

    const onDeleteStaff = (id) => {
        deleteStaff(id).then((res) => {
            successToast("Yönetici", "Yönetici başarıyla silindi")
            setOpenDeleteModal(false)
            onGetStaffList()
        })
    }

    useEffect(() => {
        onGetStaffList()
    }, [])

    return (
        <div className="white-container">
            <div className="flex items-center justify-between pb-3">
                <div className="flex space-x-2">
                    <Button
                        size="medium"
                        title="Excel Aktar"
                        type="main-success-button"
                        buttonType="button"
                        onClick={() => {
                            onDownloadExcel("Yöneticiler", tableData)
                        }}
                    />
                </div>
                <div className="flex space-x-2">
                    <SearchBoxInput
                        label="Kullanıcı Ara"
                    />
                </div>
            </div>
            {loading ?
                <div className="lg:h-[80px]">
                    <Loading width={50} height={50} />
                </div>
                :
                staffList &&
                <StaffTable
                    data={staffList}
                    onDelete={(v) => setOpenDeleteModal(v)}
                    setTableData={setTableData}
                />
            }
            {openDeleteModal &&
                <AttentionModal
                    title="Yönetici Sil"
                    description="Yöneticiyi silmek istediğinize emin misiniz?"
                    buttonAction={() => onDeleteStaff(openDeleteModal)}
                    buttonText="Sil"
                    closeModal={() => setOpenDeleteModal(false)}
                />
            }
        </div>
    )
}

export default StaffList