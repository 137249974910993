import EmptyData from 'components/EmptyData/EmptyData'
import Button from 'components/FormElements/Button'
import Paginate from 'components/FormElements/Paginate'
import SelectBox from 'components/FormElements/SelectBox'
import Tab from 'components/FormElements/Tab'
import TextInput from 'components/FormElements/TextInput'
import { selectBoxItem } from 'components/Function'
import Loading from 'components/Loading/Loading'
import ProductRequestModal from 'components/Product/ProductRequestModal'
import ProductRequestTable from 'components/Product/ProductRequestTable'
import { alertToast } from 'components/Toast/AllToast'
import { useExcel } from 'hooks/useExcel'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getBrandList, getRequestList, getRequestListByBrand, getRequestListBySupplier, getSupplierList, getVersionListByBrand, getVersionListBySupplier } from 'services/allServices'
import { useGlobalStore } from 'store/GlobalStore'

function ProductRequestList() {

  const [requestList, setRequestList] = useState()
  const [openDetailModal, setOpenDetailModal] = useState(false)

  const [setPaginateTotal, visibleCount] = useGlobalStore((state) => [state.setPaginateTotal, state.visibleCount])

  const [tableData, setTableData] = useState()
  const [loading, setLoading] = useState(true)

  const [selectedTab, setSelectedTab] = useState("all")
  const [tabList, setTabList] = useState([
    { id: "supplier", name: "Tedarikçi Bazlı" },
    { id: "brand", name: "Marka Bazlı" },
  ])

  const navigate = useNavigate()
  const [changeTab, setChangeTab] = useState(false)

  const [supplierList, setSupplierList] = useState()
  const [selectedSupplier, setSelectedSupplier] = useState()

  const [brandList, setBrandList] = useState()
  const [selectedBrand, setSelectedBrand] = useState()


  const [searchParams, setSearchParams] = useSearchParams()


  const [onDownloadExcel] = useExcel()

  const onGetProcessList = () => {
    setLoading(true)
    if (selectedTab === "all") {
      getRequestList(visibleCount, searchParams.get("p")).then((res) => {
        setRequestList(res.product_list)
        setPaginateTotal(res.total_count)
        setLoading(false)
        setChangeTab(false)
      })
    }
    else if (selectedTab === "supplier" && selectedSupplier) {
      getRequestListBySupplier(visibleCount, searchParams.get("p"), selectedSupplier?.id).then((res) => {
        setRequestList(res.product_list)
        setPaginateTotal(res.total_count)
        setLoading(false)
        setChangeTab(false)
      })
    }
    else if (selectedTab === "brand" && selectedBrand) {
      getRequestListByBrand(visibleCount, searchParams.get("p"), selectedBrand?.id).then((res) => {
        setRequestList(res.product_list)
        setPaginateTotal(res.total_count)
        setLoading(false)
        setChangeTab(false)
      })
    }
  }

  const onGetSupplierList = () => {
    setRequestList([])
    getSupplierList().then(async (res) => {
      let newRes = await selectBoxItem(res.category_list, "id", "supplier_name", false, false)
      setSupplierList(newRes)
    })
  }

  const onGetBrandList = () => {
    setRequestList([])
    getBrandList(1000).then(async (res) => {
      let newRes = await selectBoxItem(res.category_list, "brand_id", "brand", "", false, false, "", false, "", false, true)
      setBrandList(newRes)
    })
  }

  useEffect(() => {
    onGetProcessList()
  }, [visibleCount, searchParams.get("p")])

  useEffect(() => {
    setRequestList([])
    if (selectedTab === "supplier" && selectedSupplier) {
      setChangeTab(false)
      onGetProcessList()
    }
    else if (selectedTab === "brand" && selectedBrand) {
      setChangeTab(false)
      onGetProcessList()
    }
    else if (selectedTab === "all") {
      setChangeTab(false)
    }
    else {
      setChangeTab(true)
    }

    if (selectedTab === "brand" && !brandList) {
      onGetBrandList()
    }
    else if (selectedTab === "all" && !searchParams.get("type")) {
      onGetProcessList()
    }
    else if (selectedTab === "supplier" && !supplierList) {
      onGetSupplierList()
    }
  }, [selectedTab])


  useEffect(() => {
    if (selectedSupplier && selectedTab === "supplier") {
      onGetProcessList()
    }
    else if (selectedBrand && selectedTab === "brand") {
      onGetProcessList()
    }
  }, [selectedSupplier, selectedBrand])


  return (
    <div className="flex flex-col space-y-5">
      <div className="flex justify-between">
        <Tab data={tabList} selectedTab={selectedTab} setSelectedTab={(e) => setSelectedTab(e)} />
        <div className="w-6/12 whitespace-nowrap">
          {
            selectedTab === "supplier" &&
            <div>
              <SelectBox
                placeholder="Tedarikçi Seçiniz"
                items={supplierList}
                setItems={setSupplierList}
                selectedItems={selectedSupplier}
                setSelectedItems={setSelectedSupplier}
                search
              />
            </div>
          }
          {
            selectedTab === "brand" &&
            <div>
              <SelectBox
                placeholder="Marka Seçiniz"
                items={brandList}
                setItems={setBrandList}
                selectedItems={selectedBrand}
                setSelectedItems={setSelectedBrand}
                search
              />
            </div>
          }
        </div>
      </div>
      <div className="white-container">
        <div className="flex items-center justify-between pb-4">
          <h4 className="text-xl font-semibold text-black-river-falls-700">Ürün Listesi</h4>
          <div className="flex items-center space-x-2">
            {/* <Button
                        type="tertiary"
                        size="medium"
                        iconLeft={<Setting4 className="mr-1" size={20} />}
                        buttonType="button"
                        onClick={() => setOpenCustomizeModal()}
                    />
                    <Button
                        type="tertiary"
                        title="Filtrele"
                        size="medium"
                        iconLeft={<FilterSearch className="mr-1" size={20} />}
                        buttonType="button"
                        onClick={() => setOpenFilterModal(true)}
                    /> */}
            <Button
              size="medium"
              title="Excel Aktar"
              type="main-success-button"
              buttonType="button"
              onClick={() => {
                onDownloadExcel("Süreçteki Ürünler", tableData)
              }}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          {changeTab ?
            <div className="flex justify-center w-full text-xl font-bold text-black-river-falls-500">Lütfen {(selectedTab === "supplier" && "tedarikçi seçiniz.") || (selectedTab === "brand" && "marka seçiniz")}</div>
            :
            loading ?
              <Loading />
              :
              requestList.length > 0 ?
                <ProductRequestTable data={requestList} setTableData={setTableData} openDetailModal={(e) => setOpenDetailModal(e)} />
                :
                <EmptyData
                  text={selectedTab === "supplier" ? "Seçilen tedarikçiye ait ürün bulunamadı" : selectedTab === "brand" ? "Seçilen markaya ait ürün bulunamadı" : "Veri bulunamadı"}
                />
          }
          {!loading && !changeTab &&
            <Paginate />
          }
        </div>

        {openDetailModal &&
          <ProductRequestModal
            closeModal={() => setOpenDetailModal(false)}
            data={openDetailModal}
          />
        }
      </div>
    </div>
  )
}

export default ProductRequestList