import React from "react";

function CheckBox({
  id,
  name,
  label,
  description,
  disabled,
  onChange,
  checked,
  style
}) {
  return (
    <div className={`flex items-center ${style && style}`}>
      <input
        type="checkbox"
        id={id}
        name={name}
        onChange={() => {
          !disabled && onChange && onChange(!checked);
        }}
        checked={checked}
        className={
          disabled
            ? "w-4 h-4 text-border rounded-full  bg-textSecondary ring-0 cursor-not-allowed"
            : "w-4 h-4 accent-porcelain-rose-500 hover:border-black-river-falls-400 border-2  border-transparent  text-sm cursor-pointer rounded-full outline-none ring-0 focus:ring-0 focus:outline-none focus:border-none focus:ring-transparent"
        }
        disabled={disabled}
      />

      <div className="flex flex-col ml-2">
        <label
          className={` w-full text-black-river-falls-500 text-sm mb-0 ${disabled ? "cursor-not-allowed" : "cursor-pointer"} `}
          htmlFor={name}
        >
          {label}
        </label>
        <span
          className={"text-sm text-border cursor-pointer"}
          onClick={() => {
            !disabled && onChange(!checked);
          }}
        >
          {description}
        </span>
      </div>
    </div>
  );
}

export default CheckBox;
