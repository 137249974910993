import create from "zustand";
import { devtools } from "zustand/middleware";

export const useGlobalStore = create(
  devtools((set, get) => ({
    selectedMenuItem:
      JSON.parse(localStorage.getItem("__selectedMenuItem")) || "1",
    setSelectedMenuItem: (value) => {
      set((state) => ({
        selectedMenuItem: value,
      }));

      get().addToLocalStorageGloabal();
    },
    paginateTotal:
      parseInt(localStorage.getItem("__paginateTotal")) || "1",
    setPaginateTotal: (value) => {
      set((state) => ({
        paginateTotal: value,
      }));

      get().addToLocalStorageGlobal();
    },

    changeControl:
      JSON.parse(localStorage.getItem("__changeControl")) || false,
    setChangeControl: (value) => {
      set((state) => ({
        changeControl: value,
      }));

      get().addToLocalStorageGlobal();
    },

    visibleCount: parseInt(localStorage.getItem("__visibleCount")) || 10,
    setVisibleCount: (value) => {
      set((state) => ({
        visibleCount: (typeof value !== "undefined") ? parseInt(value) : "",
      }));
      get().addToLocalStorageGlobal()
    },


    addToLocalStorageGlobal: () => {
      localStorage.setItem(
        "__selectedMenuItem",
        JSON.stringify(get().selectedMenuItem)
      );
      localStorage.setItem(
        "__paginateTotal",
        JSON.stringify(get().paginateChange)
      );
      localStorage.setItem(
        "__changeControl",
        JSON.stringify(get().changeControl)
      );
      localStorage.setItem(
        "__visibleCount",
        JSON.stringify(get().visibleCount)
      );
      localStorage.setItem("__headerTitle", JSON.stringify(get().headerTitle));
    },
    checkLocalStorageGloabal: () => {
      if (
        typeof localStorage.getItem("__selectedMenuItem") !== "undefined" &&
        localStorage.getItem("__selectedMenuItem") !== null &&
        localStorage.getItem("__selectedMenuItem") !== ""
      )
        set((state) => ({
          isLoggin: JSON.parse(localStorage.getItem("__selectedMenuItem")),
        }));

      if (
        typeof localStorage.getItem("__paginateTotal") !== "undefined" &&
        localStorage.getItem("__paginateTotal") !== null &&
        localStorage.getItem("__paginateTotal") !== ""
      )
        set((state) => ({
          paginateTotal: JSON.parse(
            localStorage.getItem("__paginateTotal")
          ),
        }));

      if (
        typeof localStorage.getItem("__changeControl") !== "undefined" &&
        localStorage.getItem("__changeControl") !== null &&
        localStorage.getItem("__changeControl") !== ""
      )
        set((state) => ({
          changeControl: JSON.parse(
            localStorage.getItem("__changeControl")
          ),
        }));

      if (
        typeof localStorage.getItem("__visibleCount") !== "undefined" &&
        localStorage.getItem("__visibleCount") !== null &&
        localStorage.getItem("__visibleCount") !== ""
      )
        set((state) => ({
          visibleCount:
            localStorage.getItem("__visibleCount")
          ,
        }));


    },
  }))
);
