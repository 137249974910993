import TextInput from 'components/FormElements/TextInput'
import Toggle from 'components/FormElements/Toggle'
import ModalLayout from 'components/ModalLayout/ModalLayout'
import React, { useEffect } from 'react'
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { successToast } from 'components/Toast/AllToast';
import { TrushSquare } from 'iconsax-react';
import { createStaffDepartment, getStaffDepartment, updateStaffDepartment } from 'services/allServices';

const schema = yup.object().shape({
  department_name: yup.string().required("Departman adı zorunludur"),
});

function ActionDepartmentModal({ closeModal, onGetList, id, type, onDelete }) {

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onGetDepartment = () => {
    getStaffDepartment(id).then((res) => {
      reset(res.content)
    })
  }

  const onSubmit = (data) => {
    let configure = {
      content: {
        department_name: data.department_name,
      }
    }
    if (type !== "edit") {
      createStaffDepartment(configure).then(() => {
        successToast("Departman", "Departman başarıyla oluşturuldu.")
        onGetList()
        closeModal()
      })
    }
    else {
      updateStaffDepartment(configure, id).then(() => {
        successToast("Departman", "Departman başarıyla güncellendi.")
        onGetList()
        closeModal()
      })
    }
  }

  useEffect(() => {
    onGetDepartment()
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalLayout
        title={type === "edit" ? "Departman Düzenle" : "Departman Oluştur"}
        closeModal={closeModal}
      >
        <div className="flex flex-col space-y-4 w-[480px]">
          <TextInput
            id="department_name"
            name="department_name"
            label="Departman"
            error={errors.department_name && errors.department_name.message}
            inputState={errors.department_name && "error"}
            innerRef={register("department_name")}
          />
          <div className="flex items-center justify-between">
            <Toggle
              label="Departman Durumu"
              style="flex space-x-2 items-center"
            />
            {type === "edit" &&
              <div className="flex space-x-1 items-center cursor-pointer" onClick={() => onDelete(id)}>
                <p className="text-sm text-coral-red-500">Sil</p>
                <TrushSquare size={24} className="text-coral-red-500" />
              </div>
            }
          </div>

        </div>
      </ModalLayout>
    </form>
  )
}

export default ActionDepartmentModal