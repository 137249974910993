import Button from 'components/FormElements/Button'
import SelectBox from 'components/FormElements/SelectBox'
import TextInput from 'components/FormElements/TextInput'
import { selectBoxItem } from 'components/Function'
import { TickSquare } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { createStaff, getStaff, getStaffDepartmentList, updateStaff } from 'services/allServices'
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PasswordInput from 'components/FormElements/PasswordInput'
import { TickCircle } from 'iconsax-react'
import { useParams, useNavigate } from 'react-router'
import { alertToast, successToast } from 'components/Toast/AllToast'
import InputPhone from 'components/FormElements/PhoneInput'

const schema = yup.object().shape({
    fullname: yup.string().required("Ad Soyad zorunludur"),
    email: yup.string().email().required("Lütfen geçerli bir email giriniz."),
    newpassword: yup.string().required("Şifre giriniz."),
    password_again: yup.string().required("Şifre tekrarı giriniz.").oneOf([yup.ref('newpassword'), null], 'Şifreler eşleşmiyor'),
});

function StaffProfile() {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const [departmentList, setDepartmentList] = useState()
    const [selectedDepartment, setSelectedDepartment] = useState()
    const [password, setPassword] = useState()
    const [newPassword, setNewPassword] = useState()

    const [phoneNumber, setPhoneNumber] = useState("")

    const params = useParams()
    const navigate = useNavigate()

    const onGetStaff = () => {
        getStaff(params.id).then((res) => {
            reset(res.content)
            setNewPassword(res.content.password)
            setPhoneNumber(res.content.phone)
            onGetStaffDepartmentList(res.content.department_id)
        })
    }

    const onGetStaffDepartmentList = (id) => {
        getStaffDepartmentList().then(async (res) => {
            let selectBoxList = await selectBoxItem(res.record, "id", "department_name", "", false)
            setDepartmentList(selectBoxList)
            if (params.id) {
                let selected = selectBoxList.find((item) => item.id === id)
                setSelectedDepartment(selected)
            }
        })
    }

    const onSubmit = (data) => {
        let configure = {
            content: {
                department_id: selectedDepartment ? parseInt(selectedDepartment.id) : 0,
                fullname: data.fullname,
                email: data.email,
                password: data.newpassword,
                phone: phoneNumber,
                is_admin: true
            }
        }
        if (data.newpassword) {
            if (newPassword === password) {

            }
            else {
                return alertToast("Parola", "Eski Parola Yanlış")
            }
        }
        if (params.id) {
            updateStaff(configure, params.id).then(() => {
                successToast("Yönetici", "Yönetici başarıyla güncellendi.")
                navigate("/yoneticiler/tum-yoneticiler")
            })
        }
        else {
            createStaff(configure).then(() => {
                successToast("Yönetici", "Yönetici başarıyla oluşturuldu.")
                navigate("/yoneticiler/tum-yoneticiler")
            })
        }
    }

    useEffect(() => {
        if (params.id) {
            onGetStaff()
        }
        else {
            onGetStaffDepartmentList()
        }
    }, [])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h5 className="text-xl font-semibold text-black-river-falls-700">Profil Bilgileri</h5>
            <p className="text-sm text-black-river-falls-500">Profilinizin detaylı bilgilerini yönetin.</p>
            <div className="grid grid-cols-12 gap-6 pt-5">
                <div className="col-span-12 lg:col-span-4">
                    <TextInput
                        id="fullname"
                        name="fullname"
                        label="Ad Soyad"
                        error={errors.fullname && errors.fullname.message}
                        inputState={errors.fullname && "error"}
                        innerRef={register("fullname")}
                    />
                </div>
                <div className="col-span-12 lg:col-span-4">
                    <TextInput
                        id="email"
                        name="email"
                        label="E-posta"
                        error={errors.email && errors.email.message}
                        inputState={errors.email && "error"}
                        innerRef={register("email")}
                    />
                </div>
                <div className="col-span-12 lg:col-span-4">
                    <InputPhone
                        id="phone"
                        name="phone"
                        label="Telefon"
                        type="number"
                        value={phoneNumber}
                        setValue={(v) => setPhoneNumber(v)}
                    />
                </div>
                <div className="col-span-12 lg:col-span-4">
                    <SelectBox
                        placeholder="Yönetici Departmanı"
                        items={departmentList}
                        setItems={setDepartmentList}
                        selectedItems={selectedDepartment}
                        setSelectedItems={setSelectedDepartment}
                    />
                </div>
            </div>
            <div className="pt-5">
                <h5 className="text-xl font-semibold text-black-river-falls-700">Parola</h5>
                <p className="text-sm text-black-river-falls-500">Parolanızı güncelleyin ve yönetin.</p>
                <div className="grid grid-cols-12 gap-6 pt-5">
                    {params.id &&
                        <div className="col-span-12 lg:col-span-4">
                            <PasswordInput
                                value={password}
                                onChange={(e) => setPassword(e)}
                                label="Parola"
                            />
                        </div>
                    }
                    <div className={`${params.id ? "col-span-12 lg:col-span-4" : "col-span-12 lg:col-span-6"}`}>
                        <PasswordInput
                            id="newpassword"
                            name="newpassword"
                            label="Yeni Parola"
                            error={errors.newpassword && errors.newpassword.message}
                            inputState={errors.newpassword && "error"}
                            innerRef={register("newpassword")}
                        />
                    </div>
                    <div className={`${params.id ? "col-span-12 lg:col-span-4" : "col-span-12 lg:col-span-6"}`}>
                        <PasswordInput
                            id="password_again"
                            name="password_again"
                            label="Yeni Parola Tekrar"
                            error={errors.password_again && errors.password_again.message}
                            inputState={errors.password_again && "error"}
                            innerRef={register("password_again")}
                        />
                    </div>
                </div>
                <div className="flex flex-col pt-6 space-y-2">
                    <p className="text-sm text-black-river-falls-500">Yeni parolanı oluştururken bunlara dikkat et.</p>
                    <div className="flex items-center space-x-1">
                        <TickCircle size={16} className="text-slime-girl-500" />
                        <p className="text-sm text-black-river-falls-500">En az 8 karakter uzunluğunda olmalıdır.</p>
                    </div>
                    <div className="flex items-center space-x-1">
                        <TickCircle size={16} className="text-slime-girl-500" />
                        <p className="text-sm text-black-river-falls-500">2 büyük, 2 küçük harf ve en az 1 tane @?_! gibi sembol olmalıdır.</p>
                    </div>
                </div>
            </div>
            <div className="flex justify-end pt-10 space-x-4">
                <Button
                    type="tertiary"
                    size="medium"
                    title="Vazgeç"
                />
                <Button
                    type="main"
                    size="medium"
                    title="Kaydet"
                    iconRight={<TickSquare size={20} color="white" />}
                />
            </div>

        </form>
    )
}

export default StaffProfile