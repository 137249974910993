import CategoryFilterModal from 'components/Category/CategoryFilterModal'
import CategoryTable from 'components/Category/CategoryTable'
import Button from 'components/FormElements/Button'
import Paginate from 'components/FormElements/Paginate'
import SearchBoxInput from 'components/FormElements/SearchBox'
import { Setting4 } from 'iconsax-react'
import { FilterSearch } from 'iconsax-react'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { getCategoryFilterList, getCategoryList } from 'services/allServices'
import { useGlobalStore } from 'store/GlobalStore'
import { useExcel } from 'hooks/useExcel'
import Loading from 'components/Loading/Loading'

function CategoryList() {
    const [loading, setLoading] = useState(true)
    const [categoryList, setCategoryList] = useState()
    const [setPaginateTotal, visibleCount] = useGlobalStore((state) => [state.setPaginateTotal, state.visibleCount]);
    const [openFilterModal, setOpenFilterModal] = useState(false)
    const [listType, setListType] = useState("all")
    const [data, setData] = useState()

    const [onDownloadExcel] = useExcel()

    const [tableData, setTableData] = useState()

    const tableRef = useRef()

    const location = useLocation()

    const onGetCategoryList = () => {
        setLoading(true)
        setCategoryList()
        getCategoryList(visibleCount, location.search.split("=")[1]).then((res) => {
            setCategoryList(res.category_list)
            setPaginateTotal(res.count)
            setLoading(false)
        })
    }

    const onGetCategoryFilterList = (configure) => {
        setCategoryList()
        getCategoryFilterList(visibleCount, location.search.split("=")[1], configure ? configure : data).then((res) => {
            setCategoryList(res.category_list)
            setPaginateTotal(res.count)
        })
    }

    useEffect(() => {
        if (listType === "all") {
            onGetCategoryList()
        }
        else {
            onGetCategoryFilterList()
        }

    }, [visibleCount, location.search])

    return (
        <div className="white-container">
            <div className="flex justify-between items-center pb-3">
                <div className="flex space-x-2">
                    <Button
                        size="medium"
                        title="Excel Aktar"
                        type="main-success-button"
                        buttonType="button"
                        onClick={() => {
                            onDownloadExcel("Kategoriler", tableData)
                        }}
                    />
                </div>
                <div className="flex space-x-2">
                    <SearchBoxInput
                        label="Kategori Ara"
                    />
                </div>
            </div>
            <div className="flex flex-col space-y-3">
                {loading ?
                    <Loading />
                    :
                    categoryList &&
                    <CategoryTable data={categoryList} tableRef={tableRef} setTableData={setTableData} />
                }
                {!loading ?
                    <Paginate />
                    :
                    <></>
                }
                {openFilterModal &&
                    <CategoryFilterModal
                        closeModal={() => setOpenFilterModal(false)}
                        onGetList={(data) => onGetCategoryFilterList(data)}
                        setType={(data) => setListType(data)}
                        setData={(data) => setData(data)}
                    />
                }
            </div>
        </div>
    )
}

export default CategoryList