import Button from 'components/FormElements/Button'
import SelectBox from 'components/FormElements/SelectBox'
import Tab from 'components/FormElements/Tab'
import TextInput from 'components/FormElements/TextInput'
import ProductEditRequestTable from 'components/Product/ProductEditRequestTable'
import ProductRequestModal from 'components/Product/ProductRequestModal'
import { useExcel } from 'hooks/useExcel'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { getBrandList, getProductVersion, getSupplierList, getVersionList, getVersionListByBrand, getVersionListByProduct, getVersionListBySupplier } from 'services/allServices'
import { alertToast } from 'components/Toast/AllToast'
import { selectBoxItem } from 'components/Function'
import EmptyData from 'components/EmptyData/EmptyData'
import Loading from 'components/Loading/Loading'
import Paginate from 'components/FormElements/Paginate'
import { useGlobalStore } from 'store/GlobalStore'
import { set } from 'react-hook-form'

function ProductEditRequestList() {
    const [requestList, setRequestList] = useState()
    const [tableData, setTableData] = useState()
    const [openDetailModal, setOpenDetailModal] = useState(false)
    const [selectedTab, setSelectedTab] = useState("all")
    const [loading, setLoading] = useState(true)

    const [searchProduct, setSearchProduct] = useState("")

    const [supplierList, setSupplierList] = useState()
    const [selectedSupplier, setSelectedSupplier] = useState()

    const [brandList, setBrandList] = useState()
    const [selectedBrand, setSelectedBrand] = useState()

    const [changeTab, setChangeTab] = useState(false)

    const [productDetail, setProductDetail] = useState()

    const [setPaginateTotal, visibleCount] = useGlobalStore((state) => [state.setPaginateTotal, state.visibleCount])

    const [tabList, setTabList] = useState([
        { id: "supplier", name: "Tedarikçi Bazlı" },
        { id: "brand", name: "Marka Bazlı" },
        { id: "product", name: "Ürün Bazlı" }

    ])

    const [onDownloadExcel] = useExcel()

    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    const onGetRequestList = () => {
        setLoading(true)
        if (selectedTab === "all") {
            getVersionList(visibleCount, searchParams.get("p")).then((res) => {
                setRequestList(res.product_list)
                setPaginateTotal(res.total_count)
                setLoading(false)
                setChangeTab(false)
            })
        }
        else if (selectedTab === "supplier" && selectedSupplier) {
            getVersionListBySupplier(selectedSupplier?.id, visibleCount, searchParams.get("p")).then((res) => {
                setRequestList(res.product_list)
                setPaginateTotal(res.total_count)
                setLoading(false)
                setChangeTab(false)
            })
        }
        else if (selectedTab === "brand" && selectedBrand) {
            getVersionListByBrand(selectedBrand?.id, visibleCount, searchParams.get("p")).then((res) => {
                setRequestList(res.product_list)
                setPaginateTotal(res.total_count)
                setLoading(false)
                setChangeTab(false)
            })
        }
        else if (selectedTab === "product" && searchProduct) {
            getVersionListByProduct(searchParams.get("s"), visibleCount, searchParams.get("p")).then((res) => {
                setRequestList(res.product_list)
                setPaginateTotal(res.total_count)
                setLoading(false)
                setChangeTab(false)
            })
        }
    }

    const onGetProductRequestList = () => {
        if (selectedTab === "product") {
            getVersionListByProduct(10, 0, searchParams.get("s")).then((res) => {
                setRequestList(res)
                setLoading(false)
                setChangeTab(false)
            })
        }

    }

    const onGetSupplierList = () => {
        setRequestList([])
        getSupplierList().then(async (res) => {
            let newRes = await selectBoxItem(res.category_list, "id", "supplier_name", false, false)
            setSupplierList(newRes)
        })
    }

    const onGetBrandList = () => {
        setRequestList([])
        getBrandList(1000).then(async (res) => {
            let newRes = await selectBoxItem(res.category_list, "brand_id", "brand", "", false, false, "", false, "", false, true)
            setBrandList(newRes)
        })
    }

    const onGetProductDetail = async (data) => {
        await getProductVersion(data.id).then((res) => {
            if (res.status !== "not_found") {
                setProductDetail(res)
                setOpenDetailModal(true)
            }

        })
    }

    useEffect(() => {
        if (searchParams.get("type")) {
            setSelectedTab(searchParams.get("type"))
        }
        else {
            onGetRequestList()
        }
    }, [])

    useEffect(() => {
        if (searchParams.get("p")) {
            onGetRequestList()
        }
    }, [visibleCount, searchParams.get("p")])

    useEffect(() => {
        setChangeTab(true)
        if (selectedTab === "product" && searchParams.get("s")) {
            onGetProductRequestList()
        }
    }, [searchParams.get("s")])

    useEffect(() => {
        setRequestList([])
        if (selectedTab === "supplier" && selectedSupplier) {
            setChangeTab(false)
            onGetRequestList()
        }
        else if (selectedTab === "brand" && selectedBrand) {
            setChangeTab(false)
            onGetRequestList()
        }
        else if (selectedTab === "all") {
            setChangeTab(false)
        }
        else {
            setChangeTab(true)
        }

        if (selectedTab === "brand" && !brandList) {
            onGetBrandList()
        }
        else if (selectedTab === "all" && !searchParams.get("type")) {
            onGetRequestList()
        }
        else if (selectedTab === "supplier" && !supplierList) {
            onGetSupplierList()
        }
    }, [selectedTab])


    useEffect(() => {
        if (selectedSupplier && selectedTab === "supplier") {
            onGetRequestList()
        }
        else if (selectedBrand && selectedTab === "brand") {
            onGetRequestList()
        }
    }, [selectedSupplier, selectedBrand])


    return (
        <div className="flex flex-col space-y-5">
            <div className="flex justify-between">
                <Tab data={tabList} selectedTab={selectedTab} setSelectedTab={(e) => setSelectedTab(e)} />
                <div className="w-6/12 whitespace-nowrap">
                    {
                        selectedTab === "supplier" &&
                        <div>
                            <SelectBox
                                placeholder="Tedarikçi Seçiniz"
                                items={supplierList}
                                setItems={setSupplierList}
                                selectedItems={selectedSupplier}
                                setSelectedItems={setSelectedSupplier}
                                search
                            />
                        </div>
                    }
                    {
                        selectedTab === "brand" &&
                        <div>
                            <SelectBox
                                placeholder="Marka Seçiniz"
                                items={brandList}
                                setItems={setBrandList}
                                selectedItems={selectedBrand}
                                setSelectedItems={setSelectedBrand}
                                search
                            />
                        </div>
                    }
                    {
                        selectedTab === "product" &&
                        <div className="flex items-center space-x-3">
                            <TextInput
                                label="Default Code"
                                value={searchProduct}
                                onChange={(e) => setSearchProduct(e.target.value)}
                            />
                            <Button
                                type="main"
                                size="medium"
                                title="Ara"
                                buttonType="button"
                                onClick={() => searchProduct !== "" ? navigate(`/urunler/duzenleme-talepleri?${searchParams.toString().split("&s")[0]}&s=${searchProduct}`) :
                                    alertToast("Hata", "Lütfen ürün kodu giriniz")
                                }
                            />
                        </div>
                    }
                </div>
            </div>

            <div className="white-container">
                <div className="flex items-center justify-between pb-4">
                    <h4 className="text-xl font-semibold text-black-river-falls-700">Ürün Listesi</h4>
                    <div className="flex items-center space-x-2">
                        {/* <Button
                        type="tertiary"
                        size="medium"
                        iconLeft={<Setting4 className="mr-1" size={20} />}
                        buttonType="button"
                        onClick={() => setOpenCustomizeModal()}
                    />
                    <Button
                        type="tertiary"
                        title="Filtrele"
                        size="medium"
                        iconLeft={<FilterSearch className="mr-1" size={20} />}
                        buttonType="button"
                        onClick={() => setOpenFilterModal(true)}
                    /> */}
                        <Button
                            size="medium"
                            title="Excel Aktar"
                            type="main-success-button"
                            buttonType="button"
                            onClick={() => {
                                onDownloadExcel("Süreçteki Ürünler", tableData)
                            }}
                        />
                    </div>
                </div>
                <div className="flex flex-col space-y-2">
                    {changeTab ?
                        <div className="flex justify-center w-full text-xl font-bold text-black-river-falls-500">Lütfen {(selectedTab === "product" && "ürün kodu giriniz.") || (selectedTab === "supplier" && "tedarikçi seçiniz.") || (selectedTab === "brand" && "marka seçiniz")}</div>
                        :
                        (loading ?
                            <Loading />

                            :
                            (requestList?.length > 0 ?
                                <ProductEditRequestTable data={requestList} setTableData={setTableData} openDetailModal={(e) => onGetProductDetail(e)} />
                                :
                                <EmptyData
                                    text={selectedTab === "supplier" ? "Seçilen tedarikçiye ait ürün bulunamadı" : selectedTab === "brand" ? "Seçilen markaya ait ürün bulunamadı" : selectedTab === "product" ? "Aranan Ürün Bulunamadı" : "Ürün Bulunamadı"}
                                />
                            )
                        )
                    }
                    {!loading && !changeTab &&
                        <Paginate />
                    }
                </div>
                {openDetailModal &&
                    <ProductRequestModal
                        data={productDetail}
                        closeModal={() => setOpenDetailModal(false)}
                    />
                }
            </div>
        </div>
    )
}

export default ProductEditRequestList