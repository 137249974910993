import { useState, useEffect } from 'react';

const initConfig = {
    fileName: `export-${Date.now()}.xlsx`,
    data: "",
};

export function useExcel(config = initConfig) {
    const [data, setData] = useState();

    const onClick = (fileName, tableData) => {
        if (!tableData) {
            return;
        }
        var table = `
            <!DOCTYPE html>
               <html lang="zh-Hans">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <meta http-equiv="X-UA-Compatible" content="ie=edge">
                        <title>Excel</title>
                        <style> 
                            table {
                                border-collapse:collapse;
                                background-color: #ccc;
                            }
                            tr{
                                border: 1px solid #0ff;
                                color: #000;
                            }
                            td {
                                border: 1px solid black;
                            }
                        </style>
                    </head>
                <body>
                    <table class="table-test">
                        <tr>
            `;
        for (var i = 0; i < tableData.length; i++) {
            if (i === 0) {
                for (var prop in tableData[i]) {
                    table = table + "<td>" + prop + "</td>";
                }
                table = table + "</tr>";
            }

            table = table + "<tr>";
            for (var prop in tableData[i]) {
                table = table + "<td>" + (tableData[i][prop] === null ? "" : tableData[i][prop]) + "</td>";
            }
            table = table + "</tr>";
        }

        table = table + `
                </table>
                    </body>
                        </html>
            `;
        table = table.replace(/<a[^>]*>|<\/a>/ig, "");
        table = table.replace(/<img[^>]*>/ig, "");
        table = table.replace(/<input[^>]*>|<\/input>/ig, "");
        var myBlob = new Blob([table], {
            type: 'text/html'
        });
        var url = window.URL.createObjectURL(myBlob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = fileName + ".xls";
        a.click();
        window.URL.revokeObjectURL(url);
    }


    useEffect(() => {

    }, []);

    return [onClick];
}