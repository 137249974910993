import Badges from 'components/FormElements/Badges'
import ProfileImage from 'components/FormElements/ProfileImage'
import { Chart2 } from 'iconsax-react'
import { Box } from 'iconsax-react'
import { Tag2 } from 'iconsax-react'
import { Notification } from 'iconsax-react'
import { ArrowDown2 } from 'iconsax-react'
import { Setting2 } from 'iconsax-react'
import { UserSquare } from 'iconsax-react'
import { BookSquare } from 'iconsax-react'
import { Shop } from 'iconsax-react'
import { HomeHashtag } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useAuthStore } from 'store/AuthStore'
import TopBarMenu from './TopBarMenu'
import { User } from 'iconsax-react'

function TopBar() {
    const location = useLocation()
    const [selectedLocation, setSelectedLocation] = useState()
    const [userInformations, setIsLoggin] = useAuthStore((state) => [
        state.userInformations, state.setIsLoggin
    ]);

    useEffect(() => {
        setSelectedLocation(location.pathname.split("/")[1])
    }, [location])

    return (
        <div className="px-5 py-4 bg-white">
            <div className="flex items-center h-full lg:justify-between lg:px-4">
                <div>
                    <img src="/sachaneLogo.png" alt="" />
                </div>
                <div className="flex space-x-1">
                    <Link to="/">
                        <div className={`${selectedLocation === "" ? "bg-porcelain-rose-100" : ""} flex rounded-full hover:bg-porcelain-rose-100 px-3 py-2 cursor-pointer group`}>
                            <div className="flex items-center space-x-2">
                                <HomeHashtag size={24} className={`${selectedLocation === "" ? "text-porcelain-rose-500" : "text-black-river-falls-500 group-hover:text-porcelain-rose-500"}`} />
                                <p className={`${selectedLocation === "" ? "text-porcelain-rose-500" : ""} text-sm font-seminold text-black-river-falls-500 group-hover:text-porcelain-rose-500 mt-0.5 font-semibold`}>Anasayfa</p>
                            </div>
                        </div>
                    </Link>
                    <a href="/urunler">
                        <div className={`${selectedLocation === "urunler" ? "bg-porcelain-rose-100" : ""} flex rounded-full hover:bg-porcelain-rose-100 px-3 py-2 cursor-pointer group`}>
                            <div className="flex items-center space-x-2">
                                <Box size={24} className={`${selectedLocation === "urunler" ? "text-porcelain-rose-500" : "text-black-river-falls-500 group-hover:text-porcelain-rose-500"}`} />
                                <p className={`${selectedLocation === "urunler" ? "text-porcelain-rose-500" : ""} text-sm font-seminold text-black-river-falls-500 group-hover:text-porcelain-rose-500 mt-0.5 font-semibold`}>Ürünler</p>
                            </div>
                        </div>
                    </a>
                    {/* <div className={`${selectedLocation === "raporlar" ? "bg-porcelain-rose-100" : ""} flex rounded-full hover:bg-porcelain-rose-100 px-3 py-2 cursor-pointer group`}>
                        <div className="flex items-center space-x-2">
                            <Chart2 size={24} className={`${selectedLocation === "raporlar" ? "text-porcelain-rose-500" : "text-black-river-falls-500 group-hover:text-porcelain-rose-500"}`} />
                            <p className={`${selectedLocation === "raporlar" ? "text-porcelain-rose-500" : ""} text-sm font-seminold text-black-river-falls-500 group-hover:text-porcelain-rose-500 mt-0.5 font-semibold`}>Raporlar</p>
                        </div>
                    </div>
                    <div className={`${selectedLocation === "tedarikci-yonetim" ? "bg-porcelain-rose-100" : ""} flex rounded-full hover:bg-porcelain-rose-100 px-3 py-2 cursor-pointer group`}>
                        <div className="flex items-center space-x-2">
                            <Shop size={24} className={`${selectedLocation === "tedarikci-yonetimi" ? "text-porcelain-rose-500" : "text-black-river-falls-500 group-hover:text-porcelain-rose-500"}`} />
                            <p className={`${selectedLocation === "tedarikci-yonetimi" ? "text-porcelain-rose-500" : ""} text-sm font-seminold text-black-river-falls-500 group-hover:text-porcelain-rose-500 mt-0.5 font-semibold`}>Tedarikçi Yönetimi</p>
                        </div>
                    </div>
                    <div className={`${selectedLocation === "urun-yonetimi" ? "bg-porcelain-rose-100" : ""} flex rounded-full hover:bg-porcelain-rose-100 px-3 py-2 cursor-pointer group`}>
                        <div className="flex items-center space-x-2">
                            <Box size={24} className={`${selectedLocation === "urun-yonetimi" ? "text-porcelain-rose-500" : "text-black-river-falls-500 group-hover:text-porcelain-rose-500"}`} />
                            <p className={`${selectedLocation === "urun-yonetimi" ? "text-porcelain-rose-500" : ""} text-sm font-seminold text-black-river-falls-500 group-hover:text-porcelain-rose-500 mt-0.5 font-semibold`}>Ürün Yönetimi</p>
                        </div>
                    </div> */}
                    <Link to="/markalar/tum-markalar">
                        <div className={`${selectedLocation === "markalar" ? "bg-porcelain-rose-100" : ""} flex rounded-full hover:bg-porcelain-rose-100 px-3 py-2 cursor-pointer group`}>
                            <div className="flex items-center space-x-2">
                                <BookSquare size={24} className={`${selectedLocation === "markalar" ? "text-porcelain-rose-500" : "text-black-river-falls-500 group-hover:text-porcelain-rose-500"}`} />
                                <p className={`${selectedLocation === "markalar" ? "text-porcelain-rose-500" : ""} text-sm font-seminold text-black-river-falls-500 group-hover:text-porcelain-rose-500 mt-0.5 font-semibold`}>Markalar</p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/kategoriler/tum-kategoriler">
                        <div className={`${selectedLocation === "kategoriler" ? "bg-porcelain-rose-100" : ""} flex rounded-full hover:bg-porcelain-rose-100 px-3 py-2 cursor-pointer group`}>
                            <div className="flex items-center space-x-2">
                                <Tag2 size={24} className={`${selectedLocation === "kategoriler" ? "text-porcelain-rose-500" : "text-black-river-falls-500 group-hover:text-porcelain-rose-500"}`} />
                                <p className={`${selectedLocation === "kategoriler" ? "text-porcelain-rose-500" : ""} text-sm font-seminold text-black-river-falls-500 group-hover:text-porcelain-rose-500 mt-0.5 font-semibold`}>Kategoriler</p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/tedarikciler/tum-tedarikciler">
                        <div className={`${selectedLocation === "tedarikciler" ? "bg-porcelain-rose-100" : ""} flex rounded-full hover:bg-porcelain-rose-100 px-3 py-2 cursor-pointer group`}>
                            <div className="flex items-center space-x-2">
                                <User size={24} className={`${selectedLocation === "tedarikciler" ? "text-porcelain-rose-500" : "text-black-river-falls-500 group-hover:text-porcelain-rose-500"}`} />
                                <p className={`${selectedLocation === "tedarikciler" ? "text-porcelain-rose-500" : ""} text-sm font-seminold text-black-river-falls-500 group-hover:text-porcelain-rose-500 mt-0.5 font-semibold`}>Tedarikçiler</p>
                            </div>
                        </div>
                    </Link>
                    <Link to="/yoneticiler/tum-yoneticiler">
                        <div className={`${selectedLocation === "yoneticiler" ? "bg-porcelain-rose-100" : ""} flex rounded-full hover:bg-porcelain-rose-100 px-3 py-2 cursor-pointer group`}>
                            <div className="flex items-center space-x-2">
                                <UserSquare size={24} className={`${selectedLocation === "yoneticiler" ? "text-porcelain-rose-500" : "text-black-river-falls-500 group-hover:text-porcelain-rose-500"}`} />
                                <p className={`${selectedLocation === "yoneticiler" ? "text-porcelain-rose-500" : ""} text-sm font-seminold text-black-river-falls-500 group-hover:text-porcelain-rose-500 mt-0.5 font-semibold`}>Yöneticiler</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="flex items-center space-x-2 ">
                    <div className="relative">
                        <div className="absolute right-0 flex">
                            <Badges
                                status="error"
                                size="xsmall"
                            />
                        </div>
                        <Notification size={20} className="font-bold text-black-river-falls-500" />
                    </div>
                    <div>
                        <Setting2 size={20} className="font-bold text-black-river-falls-500" />
                    </div>
                    <TopBarMenu data={userInformations} />
                </div>
            </div>
        </div>
    )
}

export default TopBar