import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import RouterList from "./routerList";
import { Toaster } from 'react-hot-toast';

function App() {


  return (
   <div>
    <RouterList/>
    <Toaster/>
   </div>
  
  );
}

export default App;
