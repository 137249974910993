import { TickCircle } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import slugify from 'slugify'

function Tab({ data, selectedTab, setSelectedTab, onClick, setSelectedName }) {

    const navigate = useNavigate()
    const location = useLocation()

    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (searchParams.get("name") && setSelectedName) {
            setSelectedName(searchParams.get("name"))
        }
        if (searchParams.get("brand")) {
            setSelectedTab(parseInt(searchParams.get("brand")))
        }
    }, [])

    return (
        <div className="flex flex-wrap w-full space-x-3">
            <div onClick={() => {
                setSelectedTab("all")
                navigate(location.pathname)
                onClick && onClick()
            }
            } className={`${selectedTab === "all" ? "text-porcelain-rose-500 bg-porcelain-rose-100" : " hover:bg-porcelain-rose-100 hover:text-porcelain-rose-500 text-black-river-falls-500"} bg-white font-semibold rounded-md p-3 cursor-pointer relative flex space-x-2 items-center`}>
                <p>Tümü</p>
                {selectedTab === "all" &&
                    <TickCircle size={20} />
                }
            </div>
            {data?.map((item, index) => {
                return <div key={index} onClick={() => {
                    setSelectedTab(item.id)
                    setSelectedName && setSelectedName(item.content.brand)
                    navigate(location.pathname + `?type=${slugify(item.id.toLowerCase())}`)
                    
                }} className={`${selectedTab === item.id ? "text-porcelain-rose-500 bg-porcelain-rose-100" : " hover:bg-porcelain-rose-100 hover:text-porcelain-rose-500 text-black-river-falls-500"} bg-white font-semibold rounded-md p-3 cursor-pointer relative flex space-x-2 items-center`}>
                    <p>{item.name}</p>
                    {selectedTab === item.id &&
                        <TickCircle size={20} />
                    }
                </div>
            })}
        </div>
    )
}

export default Tab