import React from 'react'
import { Oval } from 'react-loader-spinner'

function Loading({width, height, text, textSize,containerStyle}) {
    return (
        <div className={`${containerStyle ? containerStyle : ""} `}>
            <div className="flex justify-center">
            {/* <Oval
                height="80"
                width="80"
                radius="9"
                color="#EB6E66"
                secondaryColor='#EB6E66'
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            /> */}
            <div className="flex flex-col items-center space-y-3">
            <img src="/loadinghour.png" alt="loading" width={width ? width : 50} height={height ? height : 50} />
            <h5 className={`${textSize ? textSize : "text-xl"} text-black-river-falls-700 font-semibold`}>{text ? text : "Yükleniyor"}</h5>
            </div>
            </div>
        </div>
    )
}

export default Loading